import React, { useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Produk.service'
import moment from 'moment'
import TableSampleForm from './FormProduk'
import { Link } from '../../libs/react-mpk/components'

const TableProduk = ({
  className = '',
  showCommandbar = true
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  return (
    <>
      <TableWrapper
        baseId="mod-table-produk"
        title={t.translate('modules.product.title')}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        // hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="date"
        defaultCollapse={true}
        useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        multiColumnFilter={false}
        columns={[
          {
            label: t("modules.product.column.name"),
            searchable: true,
            sortable: true,
            key: 'name',
            // definition: {
            //   inputType: TableWrapper.inputTypes.INPUT,
            //   defaultValue: ''
            // },
            render: item => (
              <TableWrapper.Link
                to={`/internal/inline-form`}
              >
                {item.name}
              </TableWrapper.Link>)
          },
          {
            label: t("modules.product.column.code"),
            searchable: true,
            sortable: true,
            key: 'code',
            render: item => (item.code)
          },
          {
            label: t("modules.product.column.status"),
            type: TableWrapper.dataTypes.BOOLEAN,
            searchable: true,
            definition: {
              inputType: TableWrapper.inputTypes.CHECKBOX,
              defaultValue: true
            },
            render: item => (
              <TableWrapper.Status
                type={item.boolean
                  ? TableWrapper.Status.types.SUCCESS
                  : TableWrapper.Status.types.ERROR
                }
              >
                {item.boolean ? 'ACTIVE' : 'INACTIVE'}
              </TableWrapper.Status>
            )
          },
          {
            label: t("modules.product.column.url"),
            searchable: true,
            sortable: true,
            key: 'url',
            render: item => (
              (<Link
                style={{
                  cursor: 'pointer'
                }}
                to={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.url}
              </Link>)
            )
          },
          {
            label: t("column.createdAt"),
            searchable: true,
            sortable: true,
            key: "created_by",
            type: TableWrapper.dataTypes.INPUT,
            render: (item) => item.created_by,
          },
          {
            label: t("column.createdBy"),
            searchable: true,
            sortable: true,
            key: "created_at",
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.created_at).format("lll"),
          },
        ]}
        actions={[
          new TableWrapper.action(
            'Add',
            'mdi mdi-plus',
            () => {
              setSelectedItem(null)
              setShowForm(true)
            },
            true
          )
        ]}
        itemActions={[
          new TableWrapper.action(
            'Edit',
            'mdi mdi-pencil',
            (item) => {
              setSelectedItem(item)
              setShowForm(true)
            },
            true
          ),
          new TableWrapper.action('Delete', 'mdi mdi-delete', () => console.log('request delete'), true)
        ]}
        onFetchData={async query => {
          return {
            data: []
          }
          // return service.get(query)
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <TableSampleForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
    </>
  )
}

export default TableProduk
