import React, { useState } from 'react'
import { TableWrapper, TabsWrapper } from '../../../../../libs/react-mpk/wrapper'
import { inject, observer } from 'mobx-react'
import ProfileFeature from './ProfileFeature'
import t from 'counterpart'
import { useParams } from "react-router-dom";
import { toast } from '../../../../../libs/react-mpk/services'
import Operations from './Operations/Operations'
const TabDetailFeature = ({
  className = '',
  navigationStore,
  envStore,
  modalStore
}) => {
  let { id, featureId } = useParams()
  return (
    <TabsWrapper
      title={`${t.translate('modules.product.title')} | ${t.translate('modules.product.tab.features.title')} | Nama Produk`}
      baseId="mod-product-details-fitures"
      className={className}
      backTo={`/${envStore.env.applicationType}/${id}/product/feature`}
      actionType="button"
      tabs={[
        {
          label: t.translate('modules.product.tab.profile.title'),
          key: 'profile',
          render: (
            <ProfileFeature
              showCommandbar={false}
            />
          )
        },
        {
          label: t.translate('modules.product.tab.features.operations'),
          key: 'operations',
          render: (
            <Operations
              showCommandbar={false}
            />
          )
        }
      ]}
      onChange={(tab, callback) => {
        navigationStore.redirectTo(`/${envStore.env.applicationType}/${id}/product_fitures/${featureId}/${tab.key}`)
        callback()
      }}
      actions={[
        new TableWrapper.action(
          t.translate('column.delete'),
          "mdi mdi-delete",
          (item) => {
            modalStore.showConfirm({
              title: "Delete",
              children: "Apakah Anda yakin ingin menghapus Produk ini ?",
              onSubmit: async (callback) => {
                try {
                  // await deleteDataProduk(item.id)
                  TableWrapper.reload("mod-table-produk")
                  callback()
                  toast.success("Produk berhasil di hapus")
                } catch (error) {
                  toast.errorRequest(error)
                  callback()
                }

              }
            })
          },
          true
        ),
      ]}
    />
  )
}

export default inject('navigationStore', 'envStore', 'modalStore')(observer(TabDetailFeature))
