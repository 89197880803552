import React, { useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import templateService from "./Template.service";
import { FontIcon, Button, Chip } from "react-md";
import ErrorService from "../../../services/errorService";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { DataForm } from "../../../libs/react-mpk/components";
import LoadingOverlay from "react-loading-overlay";
import handleViewPDF from "../../../utilities/handleViewPdf";

const Template = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
  ...props
}) => {
  const basePath = "template";
  const baseId = "mod-template";
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [fetchParam, setFetchParam] = useState([]);
  const [data, setData] = useState([]);
  let [search, setSearch] = useState({});

  var actions = [
    new TableWrapper.action(
      t.translate("word.delete"),
      "mdi mdi-delete",
      () => {},
      true,
      true
    ),
  ];

  var itemActions = [
    new TableWrapper.action(
      t.translate("word.view"),
      "mdi mdi-eye",
      async (item) => {
        const res = await templateService.getTemplateById(item.id);
        handleViewPDF(res.data.content);
      },
      true
    ),
  ];

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
        <TableWrapper
          selectable={true}
          useFilter={false}
          baseId={baseId}
          title={t.translate(`modules.${basePath}.title`)}
          className={className}
          commandbar={{
            style: {
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: 15,
            },
          }}
          defaultData={[]}
          defaultSortBy="date"
          sideHeader={
            <DataForm
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName="mdi mdi-magnify"
              submitLabel={`${t.translate("word.search")}`}
              additionalAction={[
                {
                  show: true,
                  render: () => (
                    <Button
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        setSearch({});
                        localStorage.removeItem(`${baseId}-saved-search`);
                        setTimeout(() => {
                          if (props.isPicker) {
                            document
                              .getElementsByClassName("mdi-reload")[1]
                              .click();
                          } else {
                            document
                              .getElementsByClassName("mdi-reload")[0]
                              .click();
                          }
                        }, 1000);
                      }}
                    >
                      {t.translate("word.delete")}
                    </Button>
                  ),
                },
              ]}
              onSubmit={(values, callback) => {
                if (values["createdDate.greaterOrEqualThan"])
                  values["createdDate.greaterOrEqualThan"] = new Date(
                    values["createdDate.greaterOrEqualThan"]
                  );
                if (values["createdDate.lessOrEqualThan"])
                  values["createdDate.lessOrEqualThan"] = new Date(
                    values["createdDate.lessOrEqualThan"]
                  );
                setSearch(values);
                localStorage.setItem(
                  `${baseId}-saved-search`,
                  JSON.stringify(search)
                );
                setTimeout(() => {
                  if (props.isPicker) {
                    document.getElementsByClassName("mdi-reload")[1].click();
                  } else {
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }
                  callback("", false, false, false);
                }, 1000);
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div
                        className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                      >
                        <p>{t.translate(`word.searching`)}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.name`
                  )}`,
                  key: "name.contains",
                  type: "text",
                },
              ]}
            />
          }
          columns={[
            {
              label: t.translate("column.name"),
              searchable: true,
              sortable: true,
              key: "name",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.name,
            },
            {
              label: t.translate("column.description"),
              searchable: true,
              sortable: true,
              key: "description",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.description,
            },
            {
              label: t.translate("column.page"),
              searchable: true,
              sortable: true,
              key: "page",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.page,
            },
            {
              label: t.translate("column.code"),
              searchable: true,
              sortable: true,
              key: "code",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.code,
            },
          ]}
          actions={actions}
          itemActions={itemActions}
          onFetchData={(params) => {
            return new Promise(async (resolve, reject) => {
              try {
                params = {
                  ...search,
                  ...params,
                  sort: "createdDate,DESC",
                };
                if (params.search) {
                  params.size = 9999;
                }
                var getPage = null;

                getPage = await templateService.getTemplate(params);

                setFetchParam(params);
                setData(getPage.data);
                getPage.headers["x-pagination-count"] =
                  getPage.headers["x-total-count"];
                resolve(getPage);
              } catch (e) {
                setFetchParam(params);
                resolve();
                ErrorService(e);
              }
            });
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={true}
        />
      </LoadingOverlay>
    </>
  );
};

export default inject("envStore", "modalStore")(observer(Template));
