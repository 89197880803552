import React, { useState } from 'react'
//import { Table, TableCell, TableHeader, TableRow } from 'react-md'
import DataTable from '../DataTable'
import './DataFormListTable.scss'

const DataFormList = ({
    title           = null,
    baseId          = 'data-form-list',
    data            = [],
    columns         = [],
    onDeleteItem    = () => {},
    definitions     = [],
    ...props
}) => {
    const [showForm, setShowForm] = useState(false)
    return (
        <>
            <DataTable
                baseId={baseId}
                data={data}
                className="mpk-data-form-list-table"
                columns={columns}
                // itemActions={[
                //     {
                //         label: t.translate('mpk.column.edit'),
                //         iconClassName: 'mdi mdi-pencil',
                //         onClick: (item, i) => {
                //           setShowForm(true)
                //         }
                //       },
                //       {
                //         label: t.translate('mpk.column.delete'),
                //         iconClassName: 'mdi mdi-delete',
                //         onClick: (item, i) => {
                //           onDeleteItem(item, i)
                //         }
                //       }
                // ]}
                loading={false}
                fit={false}
                {...props}
            />
        </>
    )
}

export default DataFormList
