import { makeAutoObservable } from "mobx";
import cookies from "react-cookies";
import moment from "moment";

const setCookie = (cookieName, value, config) => {
  let newCookie = `${cookieName}=${value};SameSite=None;Secure`;
  if (config.maxAge)
    newCookie += `;expires${moment()
      .add(config.newAge, "millisecond")
      .toDate()}`;
  document.cookie = newCookie;
};

class AuthStore {
  user = {};
  isLoggedIn = false;
  logoutAction = null;
  tokenNames = {
    accessToken: "SP_ACCESS_TOKEN",
    refreshToken: "SP_REFRESH_TOKEN",
    guestToken: "SP_GUEST_TOKEN",
    accessKey: "SP_ACCESS_KEY",
    tfaSkip: "TFA_SKIP",
    isTFA: "IS_TFA",
  };
  hasAccessToken = cookies.load(this.tokenNames.accessToken);
  sameSite = "lax";

  constructor() {
    makeAutoObservable(this);
  }

  setSameSite(sameSite) {
    this.sameSite = sameSite;
  }

  setIsLoggedIn(isLoggedIn) {
    this.isLoggedIn = isLoggedIn;
  }

  setTokenNames(
    accessToken = this.tokenNames.accessToken,
    refreshToken = this.tokenNames.refreshToken,
    guestToken = this.tokenNames.guestToken,
    accessKey = this.tokenNames.accessKey
  ) {
    return new Promise((resolve) => {
      this.tokenNames.accessToken = accessToken;
      this.tokenNames.refreshToken = refreshToken;
      this.tokenNames.guestToken = guestToken;
      this.tokenNames.accessKey = accessKey;
      this.hasAccessToken = cookies.load(this.tokenNames.accessToken);
      resolve();
    });
  }

  setHasAccessToken(hasAccessToken) {
    this.hasAccessToken = hasAccessToken;
  }

  setProfile(user) {
    this.user = user;
    this.isLoggedIn = user ? true : false;
  }

  setLogoutAction(logoutAction) {
    this.logoutAction = logoutAction;
  }

  clearCookie = (opt = { path: "/" }) => {
    if (this.sameSite === "None") {
      setCookie(this.tokenNames.accessToken, "", { maxAge: 0 });
      setCookie(this.tokenNames.refreshToken, "", { maxAge: 0 });
      setCookie(this.tokenNames.accessKey, "", { maxAge: 0 });
      setCookie(this.tokenNames.isTFA, "", { maxAge: 0 });
    } else {
      cookies.remove(this.tokenNames.accessToken, opt);
      cookies.remove(this.tokenNames.refreshToken, opt);
      cookies.remove(this.tokenNames.accessKey, opt);
      cookies.remove(this.tokenNames.isTFA, opt);
    }
  };

  logout = (redirectUri, autoBack = false) => {
    redirectUri = redirectUri || window.location.origin;
    if (this.logoutAction)
      this.logoutAction(
        window.encodeURIComponent(redirectUri),
        autoBack ? 1 : 0,
        () => {
          this.user = {};
          this.isLoggedIn = false;
          this.clearCookie();
        }
      );
    else {
      this.user = {};
      this.isLoggedIn = false;
      this.clearCookie();
    }
  };

  setAccessToken = (accessToken, config = { path: "/" }) => {
    if (this.sameSite === "None") {
      setCookie(this.tokenNames.accessToken, accessToken, config);
    } else {
      cookies.save(this.tokenNames.accessToken, accessToken, config);
    }
  };

  getAccessToken = () => {
    let accessToken = cookies.load(this.tokenNames.accessToken);
    return accessToken;
  };

  setRefreshToken = (refreshToken, config = { path: "/" }) => {
    if (this.sameSite === "None") {
      setCookie(this.tokenNames.refreshToken, refreshToken, config);
    } else {
      cookies.save(this.tokenNames.refreshToken, refreshToken, config);
    }
  };

  getRefreshToken = () => {
    let refreshToken = cookies.load(this.tokenNames.refreshToken);
    return refreshToken;
  };

  getGuestToken = () => {
    let guestToken = cookies.load(this.tokenNames.guestToken);
    return guestToken;
  };

  setAccessKey = (accessKey, config = { path: "/" }) => {
    if (this.sameSite === "None") {
      setCookie(this.tokenNames.accessKey, accessKey, config);
    } else {
      cookies.save(this.tokenNames.accessKey, accessKey, config);
    }
  };

  getAccessKey = () => {
    let accessKey = cookies.load(this.tokenNames.accessKey);
    return accessKey;
  };

  setSkipTFA = (SkipTFA, config = { path: "/", maxAge: 60 * 60 * 24 * 7 }) => {
    cookies.save(this.tokenNames.tfaSkip, SkipTFA, config);
  };

  getSkipTFA = () => {
    let tfaSkip = cookies.load(this.tokenNames.tfaSkip);
    return tfaSkip;
  };
  removeSkipTFA = (opt = { path: "/" }) => {
    cookies.remove(this.tokenNames.tfaSkip, opt);
  };

  setIsTFA = (IsTFA, config = { path: "/", maxAge: 60 * 60 * 24 }) => {
    cookies.save(this.tokenNames.isTFA, IsTFA, config);
  };
  getIsTFA = () => {
    let IsTFA = cookies.load(this.tokenNames.isTFA);
    return IsTFA;
  };

  refreshingToken = () => {
    return new Promise((resolve) => {
      window.dispatchEvent(
        new CustomEvent("sso-custom-message", {
          detail: {
            eventName: "check-token",
            callback: (accessToken) => resolve(accessToken),
          },
        })
      );
    });
  };

  hasPermission = (resourceUri, resources = this.user.resources) => {
    if (resourceUri === "") return true;
    else {
      const uris = resourceUri.split(",");
      let allowed = false;
      for (let uri of uris) {
        allowed = resources.indexOf(uri.replace(/ /g, "")) >= 0;
        if (allowed) break;
      }
      return allowed;
    }
  };

  saveAccessToken = ({ access_token, refresh_token, expires_in }) => {
    const cookieOptions = {
      path: "/",
      maxAge: expires_in,
      httpOnly: false,
    };
    cookies.save(this.tokenNames.accessToken, access_token, cookieOptions);
    cookies.save(this.tokenNames.refreshToken, refresh_token, cookieOptions);
    this.setHasAccessToken(true);
    // accessTokenName: "SP_ACCESS_TOKEN",
    // refreshTokenName: "SP_REFRESH_TOKEN",
    // accessKeyName: "SP_ACCESS_KEY",
  };
}

export default AuthStore;
