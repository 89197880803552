/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { DataForm, DataTable, DividerLabel } from "../../../../libs/react-mpk/components";
import { TableWrapper } from "../../../../libs/react-mpk/wrapper"
import { toast } from "../../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import { Button, Select, TextField } from "react-md";
import _ from "lodash";
import t from 'counterpart'
const AddProduk = ({ visible = false, onRequestClose = null, baseId, item, ...props }) => {
    const [ready, setReady] = useState(true)
    const [active, setActive] = useState(1)
    useEffect(() => {
        if (visible) {
            setReady(true)
        }
    }, [visible])
    const [data, setData] = useState({
        profile_nama: '',
        email: '',
        instansi: '',
        telepon: '',
        wa: '',
        tujuan: '',
        nama: '',
        isme: false,
        agree: false
    })
    const [dataBackend, setDataBackend] = useState([])
    const _handleInputParameter = (value, i, field) => {
        setDataBackend(e => {
            let draf = [...e]
            draf[i][field] = value
            return draf
        })
    }
    const _handleDeleteParameter = (i) => {
        setDataBackend(e => {
            let draf = [...e]
            draf.splice(i, 1)
            return draf
        })
    }
    const _handleAddParameter = (i) => {
        setDataBackend(e => {
            let draf = [...e]
            draf.push(
                {
                    name: '',
                    description: '',
                    location: 'path',
                    jenisData: 'string',
                    mandatory: 'tidak'
                }
            )
            return draf
        })
    }
    return ready && (
        <DataForm
            baseId="form-resource"
            title={t('modules.product.tab.features.formFeatures')}
            className="mpk-full full-height"
            style={{
                width: '80vw',
            }}
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                setReady(false)
                onRequestClose()
            }}
            defaultData={
                data
            }
            definitions={[
                {
                    render: (
                        <DividerLabel className='mpk-margin-N margin-top'>
                            {t("column.general")}
                        </DividerLabel>
                    )
                },
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t("modules.product.column.name"),
                    key: 'name'
                },
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t("modules.product.column.code"),
                    key: 'code',
                },
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t("modules.product.column.description"),
                    key: 'description',
                },
                {
                    inputType: DataForm.inputTypes.CHECKBOX,
                    label: t("modules.product.tab.features.defaultFeature"),
                    key: 'defaultFeature',
                },
            ]}
            onChange={(data, key, value) => {
                // setData(data)
                return data
            }}
            submitLabel={t("column.simpan")}
            onSubmit={async (data, callback) => {
                if (active === 1) {
                    if (!data.isme) {
                        callback(new Error(t.translate('column.notIsme')), true, false)
                    } else if (!data.agree) {
                        callback(new Error(t.translate('column.notAgreeAlert')), true, false)
                    } else {
                        setActive(2)
                        callback('Formulir pendaftaran telah terisi', false, false)
                    }
                } else {
                    try {
                        let api = localStorage.getItem(`selected-item-api`)
                        if (!api) {
                            callback('Pilih API Terlebih dahulu', true, false)
                        } else {
                            data.profile = {
                                nama: data.profile_nama,
                                email: data.email,
                                instansi: data.instansi,
                                telepon: data.telepon[0],
                                wa: data.wa[0],
                                tujuan: data.tujuan,
                            }
                            delete data.profile_nama
                            delete data.email
                            delete data.instansi
                            delete data.telepon
                            delete data.wa
                            delete data.tujuan
                            data.tipeLayanan = 'Development'
                            data.status = 'Belum Proses'
                            data.api = JSON.parse(api)
                            // await service.postService(data, isAdmin)
                            TableWrapper.reload(baseId)
                            callback(item ? "Data Berhasil Diedit" : "Data Berhasil Ditambah", false, true)
                            setActive(1)
                            setReady(false)
                            onRequestClose()
                        }
                    } catch (error) {
                        callback(error, true, false)
                    }
                }
            }}
        />
    );
};

export default inject("envStore", "authStore")(observer(AddProduk));
