import React from "react";
import { withRouter } from "react-router-dom";
import cookies from "react-cookies";
import { inject, observer } from "mobx-react";
import http from "../services/http.service";
import qs from "query-string";
import _, { cloneDeep } from "lodash";
import { LoaderInfo, Hint, TFAPage, PasswordExpiration } from "../components";
import t from "counterpart";
import { Button, FontIcon } from "react-md";

const TFAAktif = false;

const errorTypes = {
  noAccessToken: "NO_ACCESS_TOKEN",
};

const requestOptions = {
  useDefaultHost: false,
  useDefaultBaseUrl: false,
  useDefaultHeader: true,
};

const ssoWrapper =
  (options = {}) =>
  (WrappedComponent) => {
    const __url = {
      login: "/api/sso/login",
      exchangeToken: "/api/sso/exchangeToken/{code}",
      refreshToken: "/api/sso/refreshToken/{refreshToken}",
      me: "/api/sso/me",
      logout: null,
    };

    const { url = {}, ...__options } = options;

    let opt = Object.assign(
      {
        url: { ...__url, ...url },
        onNoAccessToken: null,
        accessTokenName: "SP_ACCESS_TOKEN",
        refreshTokenName: "SP_REFRESH_TOKEN",
        accessKeyName: "SP_ACCESS_KEY",
        showErrorUnauthorized: true,
        onComplete: null,
        basePath: "/",
        isAutoComplete: true,
      },
      __options
    );

    class sso extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          inProgress: true,
          isRefreshingToken: false,
          currentError: null,
          resolveQueue: [],
          showTFA: false,
          isExpired: false,
          showPasswordExpiration: false,
        };

        const { accessToken, refreshToken, accessKey } =
          props.authStore.tokenNames;
        opt.accessTokenName = accessToken;
        opt.refreshTokenName = refreshToken;
        opt.accessKeyName = accessKey;
      }

      async componentDidMount() {
        if (!this.props.authStore.isLoggedIn) {
          await this.setErrorHandler();
          await this.setLogoutAction();
          this.checkingAuth();
          if (this.props.router)
            this.props.navigationStore.setRouter(this.props.router);
        } else {
          this.getProfile();
        }
        // if(this.props.envStore.widget.active)
        window.addEventListener("sso-custom-message", async (e) => {
          let { eventName, data, callback } = e.detail;
          switch (eventName) {
            case "check-token":
              let res = await this.checkToken(true);
              if (callback) callback(res.accessToken);
              break;
            case "update-access-token":
            case "reload":
              console.log("update-access-token");
              const { accessToken, accessKey, access_key } = data;
              if (accessToken) this.props.authStore.setAccessToken(accessToken);
              if (accessKey || access_key)
                this.props.authStore.setAccessToken(accessKey || access_key);

              if (eventName === "reload") {
                this.setState(
                  {
                    inProgress: true,
                    currentError: null,
                  },
                  this.checkingAuth
                );
              }
              break;
            default:
              return;
          }
        });
      }

      componentWillUnmount() {
        window.removeEventListener("sso-custom-message", () => {}, false);
      }

      setErrorHandler = () => {
        return new Promise((resolve) => {
          const { widget } = this.props.envStore;
          http.setErrorHanlder((err) => {
            if (err.response) {
              const { data } = err.response;
              err.message =
                data.message ||
                data.error_description ||
                data.error ||
                data.errmsg ||
                data.errorMessage ||
                data.detail ||
                data;
              err.message =
                typeof err.message === "object"
                  ? err.message[this.props.envStore.locale.code]
                    ? err.message[this.props.envStore.locale.code]
                    : err.message
                  : err.message;

              if (err.response.status === 500) {
                if (widget.active) this.handleErrorWidget(err);
                else this.props.navigationStore.errorRequest(err);
              } else {
                let authenticate =
                  err.response.headers["www-authenticate"] ||
                  err.response.headers["WWW-authenticate"];
                if (authenticate) {
                  authenticate = authenticate
                    .replace(/, /g, "&")
                    .replace(/,/g, "&")
                    .replace(/"/g, "");
                  let parsed = qs.parse(authenticate);
                  err.response.message =
                    parsed.error_description || parsed.error;
                  err.message =
                    parsed.error_description ||
                    err.message ||
                    err.response.headers["www-authenticate"];
                  err.response.statusText =
                    err.response.status === 401
                      ? "Unauthorized"
                      : "Bad Request";
                }

                if (
                  err.message &&
                  typeof err.message === "string" &&
                  err.message.match(
                    new RegExp(
                      "(session|token expired|Authorization header not found)",
                      "ig"
                    )
                  )
                ) {
                  // this.props.dialogActions.showAlert(
                  //   t.translate('word.message'),
                  //   t.translate('sentence.confirm.acccessTokenExpired')
                  // )
                  if (widget.active) this.handleErrorWidget(err);
                  else this.props.navigationStore.errorRequest(err);
                } else {
                  if (err.response.status === 401) {
                    if (widget.active) this.handleErrorWidget(err);
                    else {
                      if (opt.showErrorUnauthorized)
                        this.props.navigationStore.errorRequest(err);
                      else this.props.authStore.logout(window.location.href);
                    }
                  } else {
                    return err;
                  }
                }
              }
            } else err.message = "Request Error. Check your connection!";
            return err;
          });
          resolve();
        });
      };

      handleErrorWidget = (err) => {
        let { widget } = this.props.envStore;
        if (widget.active) {
          if (widget.inline) {
            let { parentOrigin } = widget;
            window[widget.inline ? "parent" : "opener"].postMessage(
              {
                eventName: "error",
                data: {
                  id: widget.id,
                  errorMessage: err.message || "Failed",
                },
                id: widget.id,
              },
              parentOrigin
            );
          } else {
            window.close();
          }
        }
        this.setState({ inProgress: false, currentError: err });
      };

      setLogoutAction = () => {
        return new Promise(async (resolve) => {
          this.props.authStore.setLogoutAction(
            (redirectUri, autoBack = 0, callback) => {
              const { clientId, state } = this.props.envStore.env.apiGateway;
              const accessToken = this.props.authStore.getAccessToken();
              if (!opt.url.logout) callback();
              setTimeout(async () => {
                let q = {
                  client_id: clientId,
                  access_token: accessToken,
                  redirect_uri: redirectUri,
                  auto_back: autoBack,
                  state,
                };

                if (opt.url.logout) {
                  let resLogout = await fetch(
                    `${opt.url.logout}?${qs.stringify(q)}`
                  );
                  resLogout = await resLogout.text();
                  window.open(resLogout, "_self");
                } else
                  window.open(
                    `${
                      this.props.envStore.env.sso.host
                    }/auth/oauth/logout?${qs.stringify(q)}`,
                    "_self"
                  );
              });
            }
          );

          resolve();
        });
      };

      checkingAuth = async () => {
        let { onNoAccessToken } = opt;
        try {
          let { accessToken, toExchange } = await this.checkToken();
          if (toExchange) {
            accessToken = await this.exchangeToken();
          }
          let { widget } = this.props.envStore;
          console.log(
            "🚀 ~ file: sso.js ~ line 214 ~ sso ~ checkingAuth= ~ widget",
            widget
          );
          if (TFAAktif && widget.active !== true) {
            let TFA = this.props.authStore.getIsTFA();
            let skipTfa = this.props.authStore.getSkipTFA();
            if (TFA === "true") {
              this.setState({ showTFA: true });
            } else {
              if (skipTfa || TFA === "DONE") {
                if (this.state.isExpired) {
                  this.setState({ showPasswordExpiration: true });
                } else {
                  this.endProcess(false, accessToken ? true : false);
                }
              } else {
                this.setState({ showTFA: true });
              }
            }
          } else {
            this.endProcess(false, accessToken ? true : false);
          }
        } catch (error) {
          switch (error.errorType) {
            case errorTypes.noAccessToken:
              if (onNoAccessToken) onNoAccessToken(this.endProcess, this.props);
              else {
                const { widget } = this.props.envStore;
                if (widget.active)
                  this.handleErrorWidget(
                    new Error("Access Token or Access Key is required!")
                  );
                else this.login();
              }
              break;
            default:
              this.props.navigationStore.errorRequest(error);
              break;
          }
        }
      };

      login = () => {
        http
          .request({
            method: http.methods.GET,
            url: `${opt.url.login}?${qs.stringify({
              redirect_uri: window.encodeURIComponent(window.location.href),
            })}`,
            options: requestOptions,
          })
          .then((res) => {
            window.open(res.data, "_self");
          });
      };

      endProcess = (directToLogin = false, isGetUserProfile = true) => {
        if (directToLogin) this.login();
        else {
          this.setHttpInterceptors();
          this.getProfile(isGetUserProfile);
        }
      };

      getProfile = async (isGetUserProfile = true) => {
        let { params } = this.props.match;
        let url = opt.url.me;
        let basePath = opt.basePath;

        for (let key of Object.keys(params)) {
          url = url.replace(`:${key}`, params[key]);
          basePath = basePath.replace(`:${key}`, params[key]);
        }
        try {
          // console.log("Masuk");
          let res = await (isGetUserProfile
            ? http.get(url, {}, {}, requestOptions)
            : Promise.resolve({
                data: {
                  name: "Anonymous",
                  resources: [],
                  role: {
                    name: "ROLE_USER",
                  },
                },
              }));
          // console.log(res, "res");
          this.props.authStore.setProfile(res.data);

          if (http.baseUrl === "") {
            const { application, product } = res.data;
            if (application || product) {
              const baseUrl = product
                ? product.baseUrl
                : application
                ? application.baseUrl
                : null;
              http.setBaseUrl(baseUrl);
            }
          }

          if (params.companyId) http.setCompanyId(params.companyId);

          if (opt.onComplete) {
            if (opt.isAutoComplete) {
              this.setState({ inProgress: false }, () => {
                setTimeout(() => opt.onComplete(this.props, res.data));
              });
            } else {
              opt.onComplete(this.props, res.data, () => {
                this.setState({ inProgress: false });
              });
            }
          } else {
            let { menu } = res.data;
            if (menu) {
              let defaultMenu;
              if (basePath === window.location.pathname) {
                for (let item of menu) {
                  if (item.children) {
                    defaultMenu = item.children[0];
                    break;
                  }
                }
                setTimeout(() => {
                  let targetPath = defaultMenu
                    ? defaultMenu.path
                    : opt.basePath;
                  this.props.temporaryStore.setProperties(
                    "defaultPath",
                    targetPath
                  );
                  this.props.navigationStore.redirectTo(targetPath);
                }, 500);
              }
            }
            this.setState({ inProgress: false });
          }
        } catch (error) {
          console.log(error);
        }
      };

      checkToken = (forceUpdate = false) => {
        return new Promise(async (resolve, reject) => {
          let accessToken = cookies.load(opt.accessTokenName);
          let refreshToken = cookies.load(opt.refreshTokenName);
          let accessKey = cookies.load(opt.accessKeyName);
          if (accessKey) {
            let { host, baseUrl, urlAccessClientId } =
              this.props.envStore.env.widgetGateway;
            http.setHost(host);
            http.setBaseUrl(baseUrl);
            // http.setClientId(urlAccessClientId)
            resolve({ accessToken, toExchange: false });
          } else if (!accessToken || forceUpdate) {
            if (refreshToken) {
              try {
                let res = await http.get(
                  opt.url.refreshToken.replace("{refreshToken}", refreshToken),
                  {},
                  {},
                  { ...requestOptions, ignoreInterceptor: true }
                );
                this.saveAccessToken(res.data);
                resolve({
                  accessToken: res.data.access_token,
                  toExchange: false,
                });
              } catch (error) {
                reject(error);
              }
            } else {
              resolve({ accessToken: null, toExchange: true });
            }
          } else resolve({ accessToken, toExchange: false });
        });
      };

      exchangeToken = () => {
        return new Promise(async (resolve, reject) => {
          let parsedUrl = qs.parseUrl(window.location.href);
          let { widget } = this.props.envStore;
          if (parsedUrl.query.code) {
            try {
              let url = opt.url.exchangeToken.replace(
                "{code}",
                parsedUrl.query.code
              );
              let res = await http.get(url, {}, {}, requestOptions);
              this.saveAccessToken(res.data);

              let currentUrl = window.location.pathname;
              delete parsedUrl.query.code;
              delete parsedUrl.query.redirect_uri;
              delete parsedUrl.query.state;
              if (
                res.data.isTfa === "true" &&
                TFAAktif &&
                widget.active !== true
              ) {
                this.props.authStore.setIsTFA(res.data.isTfa);
                this.props.authStore.removeSkipTFA();
              }
              if (
                res?.data?.passwordExpired &&
                res?.data?.passwordExpired === "true"
              )
                this.setState({ isExpired: true });

              this.props.navigationStore.redirectTo(
                `${currentUrl}?${qs.stringify(parsedUrl.query)}`
              );
              resolve(res.data.access_token);
            } catch (error) {
              reject(error);
            }
          } else reject({ errorType: errorTypes.noAccessToken });
        });
      };

      saveAccessToken = ({ access_token, refresh_token, expires_in }) => {
        const cookieOptions = {
          path: "/",
          maxAge: expires_in,
          httpOnly: false,
        };
        cookies.save(opt.accessTokenName, access_token, cookieOptions);
        cookies.save(opt.refreshTokenName, refresh_token, cookieOptions);
        this.props.authStore.setHasAccessToken(true);
      };

      setHttpInterceptors = () => {
        const setHeaders = (config) => {
          let accessToken = cookies.load(opt.accessTokenName);
          let accessKey = cookies.load(opt.accessKeyName);
          config.headers = config.headers || {};
          if (accessToken) {
            config.headers.Authorization = "Bearer " + accessToken;
            config.withCredentials = true;
            // console.log(config)
          } else if (accessKey) {
            config.headers["X-Access-Key"] = accessKey;
          }
        };

        http.setRequestInterceptor((config, url) => {
          return new Promise(async (resolve, reject) => {
            let accessToken = cookies.load(opt.accessTokenName);
            let refreshToken = cookies.load(opt.refreshTokenName);

            if (!accessToken && refreshToken) {
              let { isRefreshingToken, resolveQueue } = this.state;
              if (isRefreshingToken) {
                resolveQueue.push(resolve);
                this.setState({ resolveQueue });
              } else {
                this.setState({ isRefreshingToken: true });
                try {
                  await this.checkToken();
                  setHeaders(config);
                  resolve(config);
                  if (resolveQueue.length > 0) {
                    for (let i = resolveQueue.length - 1; i >= 0; i--) {
                      let q = resolveQueue[i];
                      q(config);
                      resolveQueue.splice(i, 1);
                      this.setState({ resolveQueue });
                    }
                  }
                  this.setState({ isRefreshingToken: false });
                } catch (error) {
                  resolve();
                }
              }
            } else {
              setHeaders(config);
              resolve(config);
            }
          });
        });
      };

      render() {
        const { currentError, showTFA, showPasswordExpiration, isExpired } =
          this.state;
        const { widget } = this.props.envStore;
        return (
          <div className="mpk-sso mpk-full viewport-width viewport-height">
            {this.state.inProgress ? (
              <LoaderInfo className="mpk-initial-loader">
                {t.translate("mpk.sentence.gettingUserInformation")}
              </LoaderInfo>
            ) : currentError ? (
              <Hint style={{ border: "none" }} iconClassName="mdi mdi-alert">
                <div className="mpk-flex align-center">
                  <div className="flex">
                    {currentError.message || "Something Wrong"}
                  </div>
                  <Button
                    buttonType="icon"
                    themeType="outline"
                    onClick={() => {
                      this.setState({ inProgress: true });
                      if (widget.active) {
                        if (widget.inline) {
                          let { parentOrigin } = widget;
                          window[
                            widget.inline ? "parent" : "opener"
                          ].postMessage(
                            {
                              eventName: "error",
                              data: {
                                id: widget.id,
                                errorMessage: currentError.message || "Failed",
                              },
                              id: widget.id,
                            },
                            parentOrigin
                          );
                        } else {
                          window.close();
                        }
                      }
                    }}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  >
                    <FontIcon
                      iconClassName={
                        widget.inline ? `mdi mdi-reload` : "mdi mdi-close"
                      }
                      style={{ fontSize: 12 }}
                    />
                  </Button>
                </div>
              </Hint>
            ) : (
              <WrappedComponent {...this.props} />
            )}
            <TFAPage
              visible={showTFA}
              onFinish={() => {
                if (!isExpired) {
                  this.setState({ showTFA: false });
                  window.location.reload();
                } else {
                  this.setState({
                    showTFA: false,
                    showPasswordExpiration: true,
                  });
                }
              }}
            />
            <PasswordExpiration
              visible={showPasswordExpiration}
              onFinish={() => {
                this.setState({ showPasswordExpiration: false });
                window.location.reload();
              }}
            />
          </div>
        );
      }
    }

    return inject(
      "authStore",
      "envStore",
      "navigationStore",
      "temporaryStore",
      "modalStore"
    )(observer(withRouter(sso)));
  };

export default ssoWrapper;
