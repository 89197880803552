import React, { useState } from 'react'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import { DataForm, DividerLabel, DataTable } from '../../../libs/react-mpk/components';
import {
  TextField,
} from "@react-md/form";
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Button } from 'react-md';

const Profile = ({ envStore }) => {
  const [dataBackend, setDataBackend] = useState([])
  const _handleInputBackendServer = (event, i, field) => {
    setDataBackend(e => {
      let draf = [...e]
      draf[i][field] = event.target.value
      return draf
    })
  }
  const _handleDeleteBackendServer = (i) => {
    setDataBackend(e => {
      let draf = [...e]
      draf.splice(i, 1)
      return draf
    })
  }
  const _handleAddBackendServer = (i) => {
    setDataBackend(e => {
      let draf = [...e]
      draf.push(
        {
          name: '',
          host: '',
          baseUrl: ''
        }
      )
      return draf
    })
  }
  return (
    <FormWrapper
      // actions={[
      //   {
      //     label: 'Reload',
      //     iconClassName: 'mdi mdi-reload'
      //   }
      // ]}
      baseId="mod-form-profile"
      title={''}
      submitLabel={t("column.simpan")}
      defaultData={{
        "name": "",
        "description": "",
        "code": "",
        "url": "",
        "status": true,
        "hostPermission": false,
      }}
      definitions={[
        {
          render: (
            <DividerLabel className='mpk-margin-N margin-top'>
              {t("column.general")}
            </DividerLabel>
          )
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.column.name"),
          key: 'name'
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.column.description"),
          key: 'description',
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.column.code"),
          key: 'code',
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.column.url"),
          key: 'url',
        },
        {
          inputType: DataForm.inputTypes.CHECKBOX,
          label: t("modules.product.column.active"),
          key: 'status',
          width: '50%'
        },
        {
          inputType: DataForm.inputTypes.CHECKBOX,
          label: t("modules.product.column.hostPermission"),
          key: 'hostPermission',
          width: '50%'
        },
        {
          render: (
            <DividerLabel className='mpk-margin-N margin-top'>
              {t("modules.product.tab.profile.backendServer")}
            </DividerLabel>
          )
        },
        {
          render: (
            <DataTable
              baseId={'tabel-backend-server'}
              data={dataBackend}
              showIndex={false}
              className="mpk-data-form-list-table"
              columns={[
                {
                  label: t("modules.product.column.name"),
                  searchable: false,
                  sortable: false,
                  key: 'name',
                  render: (item, index) => (
                    <TextField
                      id={`text-field-name-${index}`}
                      className='mpk-margin-S margin-top margin-bottom'
                      key={'name'}
                      type='text'
                      placeholder={t("modules.product.column.name")}
                      value={item.name}
                      onChange={(e) => {
                        _handleInputBackendServer(e, index, 'name')
                      }}
                    />
                  )
                },
                {
                  label: t("modules.product.tab.profile.host"),
                  searchable: false,
                  sortable: false,
                  key: 'host',
                  render: (item, index) => (
                    <TextField
                      id={`text-field-host-${index}`}
                      className='mpk-margin-S margin-top margin-bottom'
                      key={'host'}
                      type='text'
                      placeholder={t("modules.product.tab.profile.host")}
                      value={item.host}
                      onChange={(e) => {
                        _handleInputBackendServer(e, index, 'host')
                      }}
                    />
                  )
                },
                {
                  label: t("modules.product.tab.profile.baseUrl"),
                  searchable: false,
                  sortable: false,
                  key: 'baseUrl',
                  render: (item, index) => (
                    <TextField
                      id={`text-field-baseUrl-${index}`}
                      className='mpk-margin-S margin-top margin-bottom'
                      key={'baseUrl'}
                      type='text'
                      placeholder={t("modules.product.tab.profile.baseUrl")}
                      value={item.baseUrl}
                      onChange={(e) => {
                        _handleInputBackendServer(e, index, 'baseUrl')
                      }}
                    />
                  )
                },
              ]}
              itemActions={[
                {
                  label: t.translate('mpk.column.delete'),
                  iconClassName: 'mdi mdi-delete ',
                  onClick: (item, i) => {
                    _handleDeleteBackendServer(i)
                  }
                }
              ]}
              loading={false}
              fit={false}
            />
          )
        },
        {
          render: (
            // <div>
            <Button
              // title='Tambah'
              className='mpk-margin-N margin-top'
              theme='secondary'
              themeType='contained'
              type='button'
              onClick={_handleAddBackendServer}
              style={{
                color: 'white'
              }}
            >
              Tambah
            </Button>
            // </div>
          )
        }

      ]}

      onChange={(data, key, value) => {
        return data
      }}
      onSubmit={(data, callback) => {
        console.log(data);
        setTimeout(callback, 2000)
      }}
    />
  )
}

export default inject('envStore')(observer(Profile))
