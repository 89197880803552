import { crud, http } from "../../../libs/react-mpk/services";
let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getTopup = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/topup`,
    query,
  });
};

service.getTopupById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/topup/${id}`,
  });
};

service.createTopupReport = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/topup/report`,
    data: data,
  });
};

export default service;
