import React, { useState } from 'react'
import { TableWrapper, TabsWrapper } from '../../../libs/react-mpk/wrapper'
import { inject, observer } from 'mobx-react'
import { Profile, Credential, TableResources, TableFeatures } from '.'
import t from 'counterpart'
import { useParams } from "react-router-dom";
import { toast } from '../../../libs/react-mpk/services'
const DetailsSample = ({
  className = '',
  navigationStore,
  envStore,
  modalStore
}) => {
  let { id } = useParams()
  return (
    <TabsWrapper
      title={`${t.translate('modules.product.title')} | Nama Produk`}
      baseId="mod-details-sample"
      className={className}
      backTo={`/${envStore.env.applicationType}/product/index`}
      actionType="button"
      tabs={[
        {
          label: t.translate('modules.product.tab.profile.title'),
          key: 'profile',
          render: (
            <Profile
              showCommandbar={false}
            />
          )
        },
        {
          label: t.translate('modules.product.tab.credential.title'),
          key: 'credential',
          render: (
            <Credential
              showCommandbar={false}
            />
          )
        },
        {
          label: t.translate('modules.product.tab.resources.title'),
          key: 'resources',
          render: (
            <TableResources
              showCommandbar={false}
            />
          )
        },
        {
          label: t.translate('modules.product.tab.features.title'),
          key: 'features',
          render: (
            <TableFeatures
              showCommandbar={false}
            />
          )
        },
        {
          label: t.translate('modules.product.tab.support.title'),
          key: 'support',
          render: (
            <Profile
              showCommandbar={false}
            />
          )
        },
        {
          label: t.translate('modules.product.tab.templatePermission.title'),
          key: 'template_permission',
          render: (
            <Profile
              showCommandbar={false}
            />
          )
        },
      ]}
      onChange={(tab, callback) => {
        navigationStore.redirectTo(`/${envStore.env.applicationType}/${id}/product/${tab.key}`)
        callback()
      }}
      actions={[
        new TableWrapper.action(
          t.translate('column.delete'),
          "mdi mdi-delete",
          (item) => {
            modalStore.showConfirm({
              title: "Delete",
              children: "Apakah Anda yakin ingin menghapus Produk ini ?",
              onSubmit: async (callback) => {
                try {
                  // await deleteDataProduk(item.id)
                  TableWrapper.reload("mod-table-produk")
                  callback()
                  toast.success("Produk berhasil di hapus")
                } catch (error) {
                  toast.errorRequest(error)
                  callback()
                }

              }
            })
          },
          true
        ),
      ]}
    />
  )
}

export default inject('navigationStore', 'envStore', 'modalStore')(observer(DetailsSample))
