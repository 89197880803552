import React from 'react'
import { FontIcon } from 'react-md'
import { Link } from '..'
import { inject, observer } from 'mobx-react'
import './CompanyCard.scss'
import { toast } from "../../services";

const CompanyCard = ({
  className = '',
  href = '',
  thumbnail = false,
  value = {},
  disabled = false,
  ...props
}) => {
  let subscriptions = value.subscriptions
  const { host } = props.envStore.env.sso;

  return disabled ? (
    <div
      className={thumbnail ? 'mpk-full full-width' : ''}
    >
      <div className={`mpk-company-card mpk-border solid dark ${className} ${thumbnail ? 'thumbnail mpk-flex align-center border-bottom' : 'border-all thin '}`}>
        <div className="image mpk-flex justify-center align-center">
          {value.icon ? (
            <img src={host + value.icon} alt={value.name} />
          ) : (
            <FontIcon iconClassName="mdi mdi-atom" />
          )}
        </div>
        <div className={`mpk-padding-N padding-all ${thumbnail ? '' : 'mpk-border thin solid dark border-top'}`}>
          <div className="mpk-font weight-B">{value.name}</div>
          <div className="mpk-font size-NS">{value.industry}</div>
        </div>
      </div>
    </div>
  ) : (
    <Link
      to={subscriptions.length > 0 ? href : '#'}
      className={thumbnail ? 'mpk-full full-width' : ''}
      onClick={e => {
        if (subscriptions.length > 0) {
          e.preventDefault()
          e.stopPropagation()
          props.navigationStore.redirectTo(href)
        } else {
          toast.error(`Anda tidak memilik subscription (langganan) untuk mengelola ${value.name}. Hubungi ADMIN perusahaan atau beli paket produk untuk ${value.name} terlebih dahulu!`)
        }
      }}
    >
      <div className={`mpk-company-card mpk-border solid dark ${className} ${thumbnail ? 'thumbnail mpk-flex align-center border-bottom' : 'border-all thin '}`}>
        <div className="image mpk-flex justify-center align-center">
          {value.icon ? (
            <img src={host + value.icon} alt={value.name} />
          ) : (
            <FontIcon iconClassName="mdi mdi-atom" />
          )}
        </div>
        <div className={`mpk-padding-N padding-all ${thumbnail ? '' : 'mpk-border thin solid dark border-top'}`}>
          <div className="mpk-font weight-B">{value.name}</div>
          <div className="mpk-font size-NS">{value.industry}</div>
        </div>
      </div>
    </Link>

  )
}

export default inject('navigationStore', 'envStore')(observer(CompanyCard))
