import { crud, http } from "../../libs/react-mpk/services";
let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getReferalCode = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/voucher`,
    query,
  });
};

service.getReferalCodeById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/voucher/${id}`,
  });
};

service.createReferalCode = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/voucher`,
    data,
  });
};

service.updateReferalCode = async (id, data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/voucher/${id}`,
    data,
  });
};

service.deleteReferalCodeById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/voucher/${id}`,
  });
};

export default service;
