import React, {useEffect, useState} from 'react';
import './Masonry.scss';

const Masonry = ({
  baseId      = 'mpk-masonry',
  items       = [],
  className   = ''
}) => {
  const [ mounted, setMounted ] = useState(false)
  const resizeAllGridItems = () => {
    let allItems = document.getElementsByClassName(`${baseId}-grid`);
    for(let x=0;x<allItems.length;x++){
      resizeGridItem(allItems[x]);
    }
  }

  const resizeGridItem = (item) => {
    let grid = document.getElementsByClassName(`${baseId}`)[0];
    let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    let content = item.querySelector('.msn-content');
    let rowSpan = Math.ceil((content.getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = "span "+rowSpan;
    content.style.height = '100%';
  }

  useEffect(() => {
    resizeAllGridItems();
    setMounted(true)
  }, [items])

  return (
    <div className={`mpk-masonry ${baseId} ${className}`}>
      {items.map((item, i) => (
        <div 
          key={`${baseId}-grid-${i}`}
          className={`${baseId}-grid`}
        >
          <div className="msn-content">
            {item}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Masonry;