class DataFormConfig{
  constructor(){
    this.onBeforeChange = null
  }

  setOnBeforeChange(func){
    this.onBeforeChange = func
  }
}

export default new DataFormConfig()