import React, { useEffect, useState } from "react";
import CustomWrapper from "../../libs/react-mpk/wrapper/CustomWrapper";
import "./ProfilePage.scss";
import { Button, FontIcon, Label, Password, TextField } from "react-md";
import { Col, Row } from "react-bootstrap";
import { Card, CardContent, CardHeader, Container } from "@mui/material";
import service from "./Profile.service";
import { inject, observer } from "mobx-react";
import { toastError } from "../../libs/react-mpk/services/sweetToast.service";

function ProfilePage({ envStore }) {
  const [isFocused2, setIsFocused2] = useState(false);
  const [isFocused3, setIsFocused3] = useState(false);
  const [isFocused4, setIsFocused4] = useState(false);

  const [isFocused5, setIsFocused5] = useState(false);
  const [isFocused6, setIsFocused6] = useState(false);
  const [isFocused7, setIsFocused7] = useState(false);

  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onChangeProfil = (setState) => (event) => {
    setState(event.target.value);
  };

  const fetchData = async () => {
    try {
      let res = await service.fetchData();
      let { data } = res;
      setUserId(data.id);
      setUsername(data.username);
      setFullname(data.name);
      setEmail(data.email);
    } catch (error) {
      toastError("error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CustomWrapper
      baseId="mod-profile-id"
      title="Profile"
      className="mpk-full full-height"
      styleCommandBar={{
        backgroundColor: "white",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: "100%",
      }}
      rightCorner={
        <div>
          {/* <Button
            themeType="contained"
            style={{
              backgroundColor: "#F26764",
              color: "white",
              borderRadius: "100px",
              marginRight: 20,
            }}
          >
            Simpan
          </Button> */}
          <Button
            style={{
              color: "#F26764",
            }}
          >
            <FontIcon
              iconClassName={`mdi mdi-reload`}
              style={{ fontSize: "25px" }}
            />
          </Button>
        </div>
      }
      children={
        <Container>
          <Card style={{ marginTop: 20 }}>
            <CardContent>
              <Label className="title">Profile</Label>
              <Row style={{ marginBotom: -50 }}>
                <Col style={{ width: "48%", marginRight: "4%" }}>
                  <Label className="label">User Id</Label>
                  <TextField
                    className={`disabled`}
                    style={{ marginBottom: 30, borderRadius: "16px" }}
                    value={userId}
                    onChange={onChangeProfil(setUserId)}
                    disabled={true}
                  />
                  <Label className="label">Username</Label>
                  <TextField
                    className={`${isFocused2 ? "fields" : "field"}`}
                    style={{ borderRadius: "16px" }}
                    value={username}
                    onChange={onChangeProfil(setUsername)}
                    onBlur={() => {
                      setIsFocused2(false);
                    }}
                    onFocus={() => {
                      setIsFocused2(true);
                    }}
                  />
                </Col>
                <Col style={{ width: "48%" }}>
                  <Label className="label">Full Name</Label>
                  <TextField
                    className={`${isFocused3 ? "fields" : "field"}`}
                    style={{ marginBottom: 30, borderRadius: "16px" }}
                    value={fullname}
                    onChange={onChangeProfil(setFullname)}
                    onBlur={() => {
                      setIsFocused3(false);
                    }}
                    onFocus={() => {
                      setIsFocused3(true);
                    }}
                  />
                  <Label className="label">Email</Label>
                  <TextField
                    className={`${isFocused4 ? "fields" : "field"}`}
                    style={{ borderRadius: "16px" }}
                    value={email}
                    onChange={onChangeProfil(setEmail)}
                    onBlur={() => {
                      setIsFocused4(false);
                    }}
                    onFocus={() => {
                      setIsFocused4(true);
                    }}
                  />
                </Col>
              </Row>
            </CardContent>
          </Card>
          {/* <Card style={{ marginTop: 20, marginBottom: 10 }}>
            <CardContent>
              <Label className="title">Password</Label>
              <Row>
                <Col style={{ width: "32%", marginRight: "2%" }}>
                  <Password
                    className={`${isFocused5 ? "fields" : "field"}`}
                    visibilityClassName={`${isFocused5 ? "icons" : "icon"}`}
                    style={{ borderRadius: "16px" }}
                    value={currentPassword}
                    placeholder="Current Password"
                    onChange={onChangeProfil(setCurrentPassword)}
                    onBlur={() => {
                      setIsFocused5(false);
                    }}
                    onFocus={() => {
                      setIsFocused5(true);
                    }}
                  />
                </Col>
                <Col style={{ width: "32%", marginRight: "2%" }}>
                  <Password
                    className={`${isFocused6 ? "fields" : "field"}`}
                    visibilityClassName={`${isFocused6 ? "icons" : "icon"}`}
                    style={{ borderRadius: "16px" }}
                    value={newPassword}
                    placeholder="New Password"
                    onChange={onChangeProfil(setNewPassword)}
                    onBlur={() => {
                      setIsFocused6(false);
                    }}
                    onFocus={() => {
                      setIsFocused6(true);
                    }}
                  />
                </Col>
                <Col style={{ width: "32%", marginRight: "2%" }}>
                  <Password
                    className={`${isFocused7 ? "fields" : "field"}`}
                    visibilityClassName={`${isFocused7 ? "icons" : "icon"}`}
                    style={{ borderRadius: "16px" }}
                    value={confirmPassword}
                    placeholder="Confirm New Password"
                    onChange={onChangeProfil(setConfirmPassword)}
                    onBlur={() => {
                      setIsFocused7(false);
                    }}
                    onFocus={() => {
                      setIsFocused7(true);
                    }}
                  />
                </Col>
              </Row>
              <Button
                themeType="contained"
                className="ChangePass"
              >
                Change Password
              </Button>
            </CardContent>
          </Card> */}
        </Container>
      }
    />
  );
}
export default inject(
  "navigationStore",
  "envStore",
  "modalStore",
  "authStore"
)(observer(ProfilePage));
