import { React, useEffect, useState } from "react";
import { DataForm, Logo, Modal } from "../../../libs/react-mpk/components";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import "./Register.scss";
import { Button, Dialog, FontIcon, Text } from "react-md";
import { inject, observer } from "mobx-react";
import { http } from "../../../libs/react-mpk/services";
import {
  toastError,
  toastSuccess,
} from "../../../libs/react-mpk/services/sweetToast.service";
import ReCAPTCHA from "react-google-recaptcha";

const Register = ({ envStore, navigationStore }) => {
  const [disable, setDisable] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [item, setItem] = useState({
    username: "",
    name: "",
    email: "",
    mobilePhone: "00000000",
    password: "",
    verifyPassword: "",
    captchaCode: "",
  });
  const handleRecaptchaVerify = (response) => {
    if (response) {
      setTimeout(() => {
        item.captchaCode = response;
        setShowCaptcha(false);
        onSubmit();
      }, 500);
    }
  };
  const onSubmit = async () => {
    try {
      let { host } = envStore.env.sso;
      await http.post(
        `${host}/api/account/register`,
        item,
        {},
        {
          useDefaultHost: false,
          useDefaultBaseUrl: false,
          useDefaultHeader: false,
        }
      );
      toastSuccess("Berhasil", "Berhasil Daftar Akun");
      sessionStorage.setItem("verificationEmail", item.email);
      navigationStore.redirectTo(`/auth/account-verification`);
    } catch (error) {
      toastError("Error", error);
    }
  };
  return (
    <div>
      <div className="container-paper">
        <div className="container">
          <div className="brand">
            <Logo
              light={false}
              className="mpk-logo mpk-layout align-center"
              logoStyle={{
                height: "90px",
              }}
            />
          </div>
          <h1 className="decorative-text">Brand Name</h1>
          <FormWrapper
            baseId="mpk-form-wrapper-id"
            style={{
              maxWidth: "700px",
              maxHeight: "60rem",
              paddingLeft: "48px",
              paddingRight: "48px",
              height: "100%",
              boxShadow:
                "0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 40%)",
              borderRadius: "15px",
              backgroundColor: "#FFFFFF",
              marginTop: -50,
            }}
            defaultData={item}
            definitions={[
              {
                render: (
                  <div style={{ width: "100%" }}>
                    <h2 className="subTitle">Create New Account</h2>
                  </div>
                ),
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                showLabel: false,
                className: "mpk-padding-N padding-right",
                key: "name",
                type: "text",
                required: true,
                iconName: "mdi mdi-account",
                placeholder: "Full Name",
                style: { width: "50%" },
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                showLabel: false,
                key: "username",
                type: "text",
                required: true,
                iconName: "mdi mdi-account",
                placeholder: "Username",
                style: { width: "50%" },
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                showLabel: false,
                className: "mpk-padding-N padding-right",
                key: "email",
                type: "text",
                required: true,
                iconName: "mdi mdi-email",
                placeholder: "Email",
                style: { width: "50%" },
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                key: "password",
                placeholder: "Password",
                type: "password",
                required: true,
                iconName: "mdi mdi-lock",
                style: { width: "50%" },
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                key: "verifyPassword",
                className: "mpk-padding-N padding-right",
                placeholder: "Confirm Password",
                type: "password",
                required: true,
                iconName: "mdi mdi-lock",
                style: { width: "50%" },
              },
              {
                render: (
                  <Button
                    themeType="contained"
                    style={{
                      backgroundColor: disable
                        ? "#F26764"
                        : "rgba(216, 216, 216, 1)",
                      color: "white",
                      width: "100%",
                      height: "50px",
                      borderRadius: "100px",
                    }}
                    disabled={disable ? false : true}
                    onClick={() => {
                      const emailRegex =
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                      if (!emailRegex.test(item.email)) {
                        return toastError(
                          "Error",
                          "Harap Cek Kembali Format Email"
                        );
                      } else if (item.password !== item.verifyPassword) {
                        return toastError("Error", "Password Tidak Sama");
                      } else {
                        return setShowCaptcha(true);
                      }
                    }}
                  >
                    <Text className="ButtonLogin">Sign Up</Text>
                  </Button>
                ),
              },
              {
                render: (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <span className="donthaveACcount">
                      Already have an account?{" "}
                      <a onClick={() => (window.location.href = "/auth/login")}>
                        Sign in
                      </a>
                    </span>
                  </div>
                ),
              },
            ]}
            onChange={(data, key, value) => {
              if (
                data.name != "" &&
                data.username != "" &&
                data.email != "" &&
                data.password != "" &&
                data.verifyPassword != ""
              ) {
                setDisable(true);
              } else {
                setDisable(false);
              }
              setItem(data);
              return data;
            }}
          />
        </div>
      </div>
      <Dialog
        onRequestClose={() => {
          setShowCaptcha(false);
        }}
        visible={showCaptcha}
      >
        <ReCAPTCHA
          sitekey={envStore.env.captcha.siteKey}
          onChange={handleRecaptchaVerify}
        />
      </Dialog>
    </div>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(Register));
