import React, { useState, useEffect } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./logExport.service";
import { Button, Chip } from "react-md";
import ErrorService from "../../services/errorService";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { DataForm } from "../../libs/react-mpk/components";
import LoadingOverlay from "react-loading-overlay";
import iziToast from "izitoast";
import downloadjs from "downloadjs";
import { Progress } from "react-sweet-progress";

const LogExport = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
  ...props
}) => {
  const basePath = "logExport";
  const baseId = "mod-logExport";
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [fetchParam, setFetchParam] = useState([]);
  const [data, setData] = useState([]);
  let [search, setSearch] = useState({});

  const deleteBulk = async () => {
    modalStore.showConfirm({
      title: t.translate("word.delete"),
      children: t.translate("word.askConfirm"),
      onSubmit: async (callback) => {
        setPloading({ loading: true, message: t.translate("word.prosesData") });
        try {
          var proses = t.translate("word.delete");
          const selectIndex = JSON.parse(TableWrapper.getSelected(baseId));
          let selectedId = [];
          selectIndex.forEach((value) => {
            selectedId.push(data[value].id);
          });

          await service.deleteBulk(selectedId);
          iziToast.success({
            title: proses,
            message: t.translate("word.prosesDone"),
          });
          setPloading({ loading: false, message: "" });
          document.getElementsByClassName("mdi-reload")[0].click();
          callback();
        } catch (e) {
          setPloading({ loading: false, message: "" });
          callback();
          ErrorService(e);
        }
      },
    });
  };

  const deleteById = async (item) => {
    modalStore.showConfirm({
      title: t.translate("word.delete"),
      children: t.translate("word.askConfirm"),
      onSubmit: async (callback) => {
        setPloading({ loading: true, message: t.translate("word.prosesData") });
        try {
          var proses = t.translate("word.delete");
          await service.deleteById(item.id);
          iziToast.success({
            title: proses,
            message: t.translate("word.prosesDone"),
          });
          setPloading({ loading: false, message: "" });
          callback();
        } catch (e) {
          setPloading({ loading: false, message: "" });
          callback();
          ErrorService(e);
        }
      },
    });
  };

  const downloadBulk = async () => {
    modalStore.showConfirm({
      title: t.translate("word.download"),
      children: t.translate("word.askConfirm"),
      onSubmit: async (callback) => {
        setPloading({ loading: true, message: t.translate("word.prosesData") });
        try {
          var proses = t.translate("word.download");
          const selectIndex = JSON.parse(TableWrapper.getSelected(baseId));
          let selectedId = [];
          selectIndex.forEach((value) => {
            selectedId.push(data[value].id);
          });

          var getFile = await service.downloadBulk(selectedId);
          downloadjs(getFile.data, getFile.headers.filename);
          iziToast.success({
            title: proses,
            message: t.translate("word.prosesDone"),
          });
          setPloading({ loading: false, message: "" });
          callback();
        } catch (e) {
          setPloading({ loading: false, message: "" });
          callback();
          ErrorService(e);
        }
      },
    });
  };

  const downloadById = async (item) => {
    modalStore.showConfirm({
      title: t.translate("word.download"),
      children: t.translate("word.askConfirm"),
      onSubmit: async (callback) => {
        setPloading({ loading: true, message: t.translate("word.prosesData") });
        try {
          var proses = t.translate("word.download");
          var getFile = await service.downloadById(item.id);
          downloadjs(getFile.data, `${item.name}.xls`);
          iziToast.success({
            title: proses,
            message: t.translate("word.prosesDone"),
          });
          setPloading({ loading: false, message: "" });
          callback();
        } catch (e) {
          setPloading({ loading: false, message: "" });
          callback();
          ErrorService(e);
        }
      },
    });
  };

  var actions = [
    // new TableWrapper.action(
    //   t.translate("word.download"),
    //   "mdi mdi-download",
    //   () => {
    //     downloadBulk();
    //   },
    //   true,
    //   true
    // ),
    new TableWrapper.action(
      t.translate("word.delete"),
      "mdi mdi-delete",
      () => {
        deleteBulk();
      },
      true,
      true
    ),
  ];

  var itemActions = [
    new TableWrapper.action(
      t.translate("word.download"),
      "mdi mdi-download",
      async (item) => {
        downloadById(item);
      }
    ),
    new TableWrapper.action(
      t.translate("column.delete"),
      "mdi mdi-delete",
      async (item) => {
        deleteById(item);
      }
    ),
  ];

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
        <TableWrapper
          selectable={true}
          useFilter={false}
          baseId={baseId}
          title={t.translate(`modules.${basePath}.title`)}
          className={className}
          commandbar={{
            style: {
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: 15,
            },
          }}
          defaultData={[]}
          defaultSortBy="date"
          sideHeader={
            <DataForm
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName="mdi mdi-magnify"
              submitLabel={`${t.translate("word.search")}`}
              additionalAction={[
                {
                  show: true,
                  render: () => (
                    <Button
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        setSearch({});
                        localStorage.removeItem(`${baseId}-saved-search`);
                        setTimeout(() => {
                          if (props.isPicker) {
                            document
                              .getElementsByClassName("mdi-reload")[1]
                              .click();
                          } else {
                            document
                              .getElementsByClassName("mdi-reload")[0]
                              .click();
                          }
                        }, 1000);
                      }}
                    >
                      {t.translate("word.delete")}
                    </Button>
                  ),
                },
              ]}
              onSubmit={(values, callback) => {
                if (values["createdDate.greaterOrEqualThan"])
                  values["createdDate.greaterOrEqualThan"] = new Date(
                    values["createdDate.greaterOrEqualThan"]
                  );
                if (values["createdDate.lessOrEqualThan"])
                  values["createdDate.lessOrEqualThan"] = new Date(
                    values["createdDate.lessOrEqualThan"]
                  );
                setSearch(values);
                localStorage.setItem(
                  `${baseId}-saved-search`,
                  JSON.stringify(search)
                );
                setTimeout(() => {
                  if (props.isPicker) {
                    document.getElementsByClassName("mdi-reload")[1].click();
                  } else {
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }
                  callback("", false, false, false);
                }, 1000);
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div
                        className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                      >
                        <p>{t.translate(`word.searching`)}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.name`
                  )}`,
                  key: "name.contains",
                  type: "text",
                },
                {
                  inputType: inputTypes.SELECT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.status`
                  )}`,
                  key: "status.equals",
                  type: "text",
                  options: [
                    {
                      label: "COMPLETE",
                      value: "COMPLETE",
                    },
                    {
                      label: "FAILED",
                      value: "FAILED",
                    },
                  ],
                },
              ]}
            />
          }
          columns={[
            {
              label: t.translate("column.status"),
              key: "status",
              sortable: true,
              searchable: true,
              render: (d) => {
                if (d.status === "COMPLETE") {
                  return (
                    <Chip className="chip-success">
                      {d.status.toString().toUpperCase()}
                    </Chip>
                  );
                } else {
                  return (
                    <Chip className="chip-error">
                      {d.status.toString().toUpperCase()}
                    </Chip>
                  );
                }
              },
            },
            {
              label: t.translate("column.name"),
              searchable: true,
              sortable: true,
              key: "name",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.name,
            },
            {
              label: t.translate("column.contentType"),
              searchable: true,
              sortable: true,
              key: "contentType",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.contentType,
            },
            // {
            //   label: t.translate("column.progress"),
            //   searchable: true,
            //   sortable: true,
            //   key: "progress",
            //   type: TableWrapper.dataTypes.STRING,
            //   render: (item) => item.progress,
            // },
            {
              label: t.translate("column.progress") + " (%)",
              searchable: true,
              sortable: true,
              key: "progress",
              type: TableWrapper.dataTypes.NUMBER,
              render: (item) => {
                if (item.progress > 100) item.progress = 100;
                return <p>{item.progress}</p>;
              },
            },
            {
              label: t.translate("column.message"),
              searchable: true,
              sortable: true,
              key: "message",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.message,
            },
            {
              label: t.translate("column.createdBy"),
              searchable: true,
              sortable: true,
              key: "createdBy",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.createdBy,
            },
            {
              label: t.translate("column.createdDate"),
              searchable: true,
              sortable: true,
              key: "createdDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.createdDate,
            },
          ]}
          actions={actions}
          itemActions={itemActions}
          onFetchData={(params) => {
            return new Promise(async (resolve, reject) => {
              try {
                params = {
                  ...search,
                  ...params,
                  sort: "createdDate,DESC",
                };
                if (params.search) {
                  params.size = 9999;
                }
                var getPage = null;

                getPage = await service.getLogExport(params);

                setFetchParam(params);
                setData(getPage.data);
                getPage.headers["x-pagination-count"] =
                  getPage.headers["x-total-count"];
                resolve(getPage);
              } catch (e) {
                setFetchParam(params);
                resolve();
                ErrorService(e);
              }
            });
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={true}
        />
      </LoadingOverlay>
    </>
  );
};

export default inject("envStore", "modalStore")(observer(LogExport));
