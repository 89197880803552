import React from 'react'
import { Button, TextIconSpacing, FontIcon, CircularProgress } from 'react-md'

const LoadingButton = ({
  baseId            = 'mpk-loading-button',
  loading           = false,
  children          = null,
  iconClassName     = 'mdi mdi-arrow-right',
  ...props
}) => {
  return (
    <Button
      loading={loading}
      {...props}
    >
      <TextIconSpacing
        icon={loading ? (
          <CircularProgress id={`${baseId}-progress`} centered={false}/>
        ) : <FontIcon iconClassName={iconClassName}/>}
      >
        {children}
      </TextIconSpacing>
    </Button>
  )
}

export default LoadingButton
