import { React, useEffect, useState } from "react";
import { DataForm, Logo } from "../../../libs/react-mpk/components";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import "./Login.scss";
import { Button, FontIcon, Text } from "react-md";
import { Form, Row } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import loginService from "./Login.service";
import {
  toastError,
  toastSuccess,
} from "../../../libs/react-mpk/services/sweetToast.service";

const Login = ({ envStore, navigationStore, authStore }) => {
  const [check, setCheck] = useState(false);
  const [disable, setDisable] = useState(false);
  const [item, setItem] = useState({
    username: "",
    password: "",
  });
  useEffect(() => {
    let localData = localStorage.getItem("dataUser");
    if (localData) {
      setItem({ ...item, username: localData });
      setCheck(true);
    }

    if (authStore.getAccessToken(true)) {
      navigationStore.redirectTo(`/internal/dashboard`);
      window.open(`/internal/dashboard`, "_self");
    }
  }, []);

  const oAuthAuthorize = async () => {
    try {
      let { username, password } = item;
      const data = new FormData();
      data.append("grant_type", "password");
      data.append("client_id", envStore.env.apiGateway.clientId);
      data.append("client_secret", envStore.env.apiGateway.clientSecret);
      data.append("redirect_uri", "/");
      data.append("state", "state");
      data.append("response_type", "code");
      data.append("username", username);
      data.append("password", password);
      let res = await loginService.authLogin(data);
      let handleToken = await loginService.accessToken(res.data.code);
      await authStore.saveAccessToken(
        handleToken.data.access_token,
        handleToken.data.refresh_token,
        handleToken.data.expires_in
      );
      await authStore.setAccessToken(handleToken.data.access_token);
      await authStore.setRefreshToken(handleToken.data.refresh_token);
      await authStore.setIsLoggedIn(true);
      toastSuccess("Berhasil", "Berhasil Login");
      window.open(`/internal/dashboard`, "_self");
    } catch (error) {
      toastError("Error", error);
    }
  };

  return (
    <div>
      <div className="container-paper">
        <div className="container">
          <div className="brand">
            <Logo
              light={false}
              className="mpk-logo mpk-layout align-center"
              logoStyle={{
                height: "90px",
              }}
            />
          </div>
          <h1 className="decorative-text">Brand Name</h1>
          <FormWrapper
            baseId="mpk-form-wrapper-id"
            style={{
              maxWidth: "400px",
              maxHeight: "60rem",
              paddingLeft: "48px",
              paddingRight: "48px",
              height: "100%",
              boxShadow:
                "0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 40%)",
              borderRadius: "15px",
              backgroundColor: "#FFFFFF",
              marginTop: -50,
            }}
            defaultData={item}
            definitions={[
              {
                render: (
                  <div style={{ width: "100%" }}>
                    <h2 className="subTitle">Login to Your Account</h2>
                  </div>
                ),
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                showLabel: false,
                placeholder: "Username",
                key: "username",
                type: "text",
                required: true,
                iconName: "mdi mdi-account",
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                key: "password",
                placeholder: "Password",
                type: "password",
                required: true,
                iconName: "mdi mdi-lock",
              },
              {
                render: (
                  <Row style={{ marginLeft: 15, marginTop: -10 }}>
                    <label
                      htmlFor="myCheckbox"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <FontIcon
                        iconClassName={`mdi ${
                          check == true
                            ? `mdi-checkbox-marked`
                            : `mdi-checkbox-blank-outline`
                        }`}
                        style={{ color: "#F26764" }}
                        onClick={() => {
                          setCheck(!check);
                        }}
                      />
                      <span className="remember">Remember Me</span>
                    </label>
                  </Row>
                ),
              },
              {
                render: (
                  <Button
                    themeType="contained"
                    style={{
                      backgroundColor: disable
                        ? "#F26764"
                        : "rgba(216, 216, 216, 1)",
                      color: "white",
                      width: "100%",
                      height: "50px",
                      borderRadius: "100px",
                    }}
                    disabled={disable ? false : true}
                    onClick={oAuthAuthorize}
                  >
                    <Text className="ButtonLogin">Login</Text>
                  </Button>
                ),
              },
              {
                render: (
                  <span className="forgotPassword">
                    <a onClick={() => (window.location.href = "/auth/forgot")}>
                      Forgot Username or Password?
                    </a>
                  </span>
                ),
              },
              {
                render: (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <span className="donthaveACcount">
                      Don't have an account?{" "}
                      <a
                        onClick={() =>
                          (window.location.href = "/auth/register")
                        }
                      >
                        Register
                      </a>
                    </span>
                  </div>
                ),
              },
            ]}
            onChange={(data, key, value) => {
              if (data.username != "" && data.password != "") {
                setDisable(true);
              } else {
                setDisable(false);
              }
              setItem(data);
              return data;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore",
  "authStore"
)(observer(Login));
