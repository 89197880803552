import React from 'react'
import { statusTypes as types } from '../../config/constant'
import { FontIcon } from 'react-md'

const Status = ({
  className   = '',
  type        = types.IDLE,
  children    = null,
  onClick     = null,
  ...props
}) => {
  return (
    <div 
      className={`mpk-padding-S padding-left padding-right mpk-flex align-center justify-between mpk-status ${className} status-${type} ${onClick ? 'clickable' : ''}`}
      onClick={onClick}
      {...props}
    >
      <div className="flex mpk-align align-center">
        {children}
      </div>
      {onClick && <FontIcon 
        iconClassName="mdi mdi-cursor-default-outline"
        className="mpk-marign-S margin-right flex-none"
        style={{
          fontSize: 14
        }}
      />}
    </div>
  )
}

Status.types = types;

export default Status
