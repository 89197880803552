import { React, useEffect, useState } from "react";
import { DataForm, Logo } from "../../../libs/react-mpk/components";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import "./Forgot.scss";
import { Button, Dialog, FontIcon, Text } from "react-md";
import { inject, observer } from "mobx-react";
import logoForgot from "../../../assets/forget.png";
import {
  toastError,
  toastSuccess,
} from "../../../libs/react-mpk/services/sweetToast.service";
import { http } from "../../../libs/react-mpk/services";
import ReCAPTCHA from "react-google-recaptcha";

const Forgot = ({ envStore, navigationStore }) => {
  const [disable, setDisable] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [item, setItem] = useState({
    email: "",
    captchaCode: "",
  });
  const handleRecaptchaVerify = (response) => {
    if (response) {
      setTimeout(() => {
        item.captchaCode = response;
        setShowCaptcha(false);
        onSubmit();
      }, 500);
    }
  };

  const onSubmit = async () => {
    try {
      let { host } = envStore.env.sso;
      await http.post(
        `${host}/api/account/forgot_password`,
        item,
        {},
        {
          useDefaultHost: false,
          useDefaultBaseUrl: false,
          useDefaultHeader: false,
        }
      );
      toastSuccess(
        "",
        "Silahkan cek email anda, jika akun anda sudah terdaftar di website kami maka email yang berisi link untuk melakukan reset password akan dikirimkan"
      );
      navigationStore.redirectTo(`/auth/login`);
    } catch (error) {
      toastError("", error);
    }
  };
  return (
    <div>
      <div className="container-paper">
        <div className="container">
          <div className="brand">
            <Logo
              light={false}
              className="mpk-logo mpk-layout align-center"
              logoStyle={{
                height: "90px",
              }}
            />
          </div>
          <h1 className="decorative-text">Brand Name</h1>
          <FormWrapper
            baseId="mpk-form-wrapper-id"
            style={{
              maxWidth: "800px",
              maxHeight: "60rem",
              padding: 50,
              height: "100%",
              boxShadow:
                "0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 40%)",
              borderRadius: "15px",
              backgroundColor: "#FFFFFF",
              marginTop: -50,
            }}
            defaultData={[]}
            definitions={[
              {
                render: (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <img
                        src={logoForgot}
                        width={"276px"}
                        height={"250px"}
                        style={{ marginTop: 50 }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <p className="subTitle">Forgot Username or Password?</p>
                      <p className="textBody">
                        Input your email address and we will send your username
                        and instruction how to reset your password
                      </p>
                      <DataForm
                        baseId="mpk-form-wrapper-id"
                        defaultData={item}
                        definitions={[
                          {
                            inputType: DataForm.inputTypes.INPUT,
                            showLabel: false,
                            key: "email",
                            type: "text",
                            iconName: "mdi mdi-email",
                            placeholder: "Email",
                          },
                          {
                            render: (
                              <Button
                                themeType="contained"
                                style={{
                                  backgroundColor: disable
                                    ? "#F26764"
                                    : "rgba(216, 216, 216, 1)",
                                  color: "white",
                                  width: "100%",
                                  height: "50px",
                                  borderRadius: "100px",
                                }}
                                disabled={disable ? false : true}
                                onClick={() => {
                                  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                  if (emailRegex.test(item.email)) {
                                    return setShowCaptcha(true);
                                  } else {
                                    return toastError("Error", "Harap Cek Kembali Format Email");
                                  } 
                                }}
                              >
                                <Text className="ButtonLogin">Submit</Text>
                              </Button>
                            ),
                          },
                        ]}
                        onChange={(data, key, value) => {
                          if (data.email != "") {
                            setDisable(true);
                          } else {
                            setDisable(false);
                          }
                          setItem(data);
                          return data;
                        }}
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
      <Dialog
        onRequestClose={() => {
          setShowCaptcha(false);
        }}
        visible={showCaptcha}
      >
        <ReCAPTCHA
          sitekey={envStore.env.captcha.siteKey}
          onChange={handleRecaptchaVerify}
        />
      </Dialog>
    </div>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(Forgot));
