import React from 'react'
import { Router, Switch, Route, Redirect } from "react-router-dom"
import DetailProduk from './DetailProduk/TabDetailProduk'
import TableProduk from './TableProduk'
import { inject, observer } from 'mobx-react'

const Product = ({ history, navigationStore }) => {
  console.log("🚀 ~ file: Produk.routes.js ~ line 9 ~ Product ~ window.location.pathname", window.location.pathname)
  if (window.location.pathname === '/internal/product') {
    navigationStore.redirectTo('/internal/product/index')
  }
  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/internal/product/index'
          render={props => (
            <TableProduk {...props} />
          )}
        />
        <Route
          path='/internal/product/detail/:id/:tab'
          render={props => (
            <DetailProduk {...props} />
          )}
        />
      </Switch>
    </Router>
  )
}

export default inject('navigationStore')(observer(Product))