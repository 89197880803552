import React, { useState, useEffect } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./ReferalCode.service";
import { Button, Chip } from "react-md";
import ErrorService from "../../../services/errorService";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { DataForm } from "../../../libs/react-mpk/components";
import LoadingOverlay from "react-loading-overlay";
import iziToast from "izitoast";
import downloadjs from "downloadjs";
import ReferalCodeReport from "./ReferalCodeReportForm";
import { Card, CardContent, Typography } from "@mui/material";
import { format } from "../../../libs/react-mpk/services/number.service";

const ReferalCode = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
  ...props
}) => {
  const basePath = "report-referal-code";
  const baseId = "mod-report-referal-code";
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [fetchParam, setFetchParam] = useState([]);
  const [data, setData] = useState([]);
  let [search, setSearch] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [total, setTotal] = useState(0);

  var actions = [
    {
      label: `${t.translate("column.export")}`,
      iconClassName: "mdi mdi-file-export",
      onClick: async () => {
        setShowForm(true);
      },
    },
  ];

  const onCloseForm = () => {
    setShowForm(false);
    setSelectedItem(null);
  };

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
        <TableWrapper
          selectable={false}
          useFilter={false}
          baseId={baseId}
          title={t.translate(`modules.${basePath}.title`)}
          className={className}
          commandbar={{
            style: {
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: 15,
            },
          }}
          defaultData={[]}
          defaultSortBy="date"
          sideHeader={
            <DataForm
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName="mdi mdi-magnify"
              submitLabel={`${t.translate("word.search")}`}
              additionalAction={[
                {
                  show: true,
                  render: () => (
                    <Button
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        setSearch({});
                        localStorage.removeItem(`${baseId}-saved-search`);
                        setTimeout(() => {
                          if (props.isPicker) {
                            document
                              .getElementsByClassName("mdi-reload")[1]
                              .click();
                          } else {
                            document
                              .getElementsByClassName("mdi-reload")[0]
                              .click();
                          }
                        }, 1000);
                      }}
                    >
                      {t.translate("word.delete")}
                    </Button>
                  ),
                },
              ]}
              onSubmit={(values, callback) => {
                if (values["paidAt.greaterThanOrEqual"])
                  values["paidAt.greaterThanOrEqual"] = new Date(
                    values["paidAt.greaterThanOrEqual"]
                  );
                if (values["paidAt.lessThanOrEqual"])
                  values["paidAt.lessThanOrEqual"] = new Date(
                    values["paidAt.lessThanOrEqual"]
                  );
                setSearch(values);
                localStorage.setItem(
                  `${baseId}-saved-search`,
                  JSON.stringify(search)
                );
                setTimeout(() => {
                  if (props.isPicker) {
                    document.getElementsByClassName("mdi-reload")[1].click();
                  } else {
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }
                  callback("", false, false, false);
                }, 1000);
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div
                        className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                      >
                        <p>{t.translate(`word.searching`)}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.codeReferral`
                  )}`,
                  key: "referalCode.contains",
                  type: "text",
                },
                {
                  inputType: DataForm.inputTypes.DATEPICKER,
                  label: "Tanggal Awal",
                  key: "paidAt.greaterThanOrEqual",
                },
                {
                  inputType: DataForm.inputTypes.DATEPICKER,
                  label: "Tanggal Akhir",
                  key: "paidAt.lessThanOrEqual",
                },
              ]}
            />
          }
          columns={[
            {
              label: t.translate("column.codeReferral"),
              searchable: true,
              sortable: true,
              key: "referalCode",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.referalCode,
            },
            {
              label: t.translate("column.productName"),
              searchable: true,
              sortable: true,
              key: "productName",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.productName,
            },
            {
              label: t.translate("column.price"),
              searchable: true,
              sortable: true,
              key: "price",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => `Rp. ${format(item.price)}`,
            },
            {
              label: t.translate("column.qty"),
              searchable: true,
              sortable: true,
              key: "qty",
              type: TableWrapper.dataTypes.NUMBER,
              render: (item) => item.qty,
            },
            {
              label: t.translate("column.username"),
              searchable: true,
              sortable: true,
              key: "username",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.username,
            },
            {
              label: t.translate("column.paidAt"),
              searchable: true,
              sortable: true,
              key: "paidAt",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.paidAt,
            },
          ]}
          actions={actions}
          itemActions={[]}
          onFetchData={(params) => {
            return new Promise(async (resolve, reject) => {
              try {
                params = {
                  ...search,
                  ...params,
                  sort: "paidAt,DESC",
                };
                if (params.search) {
                  params.size = 9999;
                }
                var getPage = null;

                getPage = await service.getReportReferalCode(params);

                setFetchParam(params);
                setData(getPage.data);
                setTotal(getPage.headers["x-total-price"]);
                getPage.headers["x-pagination-count"] =
                  getPage.headers["x-total-qty"];
                resolve(getPage);
              } catch (e) {
                setFetchParam(params);
                resolve();
                ErrorService(e);
              }
            });
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={true}
          tableHeader={
            <Card style={{ width: "100%", marginBottom: 10 }}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  Total
                </Typography>
                <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                  Rp. {format(total)}
                </Typography>
              </CardContent>
            </Card>
          }
        />
      </LoadingOverlay>
      <ReferalCodeReport
        visible={showForm}
        onRequestClose={() => {
          onCloseForm();
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("envStore", "modalStore")(observer(ReferalCode));
