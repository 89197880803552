import React, { useState, useEffect } from 'react'
import { DataForm, LoadingButton, DataTable } from '../../../../../../libs/react-mpk/components'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Button, FontIcon, TextIconSpacing } from 'react-md'

const { inputTypes } = DataForm

const baseId = 'tabel-detail-oprations'


const DetailLampIIIPPh22 = ({
    envStore,
    ...props
}) => {

    const [defaultData, setDefaultData] = useState({})
    const [ready, setReady] = useState(true)

    return ready && (
        <>
            <div className='mpk-flex justify-end'>

                <Button
                    className='mpk-margin-N margin-bottom'
                    theme='secondary'
                    themeType='contained'
                    type='button'
                    // onClick={}
                    style={{
                        color: 'white'
                    }}
                >
                    <TextIconSpacing icon={<FontIcon iconClassName={'mdi mdi-plus'}></FontIcon>}>
                        {t('column.add')}
                    </TextIconSpacing>
                </Button>
            </div>
            <DataTable
                baseId="table-list-operations"
                title="Tabel Review Data"
                // className={className}
                data={[]}
                fit={false}
                loading={false}
                columns={[

                    {
                        label: t.translate('modules.product.tab.features.productResource'),
                        sortable: false,
                        searchable: false,
                        key: "productResource",
                        render: (item) => item.productResource,
                    },
                    {
                        label: t.translate('modules.product.tab.features.filterRule'),
                        sortable: false,
                        searchable: false,
                        key: "filterRule",
                        render: (item) => item.filterRule,
                    },
                ]}
                itemActions={[

                ]}
            />

        </>
    )
}


export default inject('envStore', 'temporaryStore', 'authStore')(observer(DetailLampIIIPPh22))