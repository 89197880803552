import { crud, http } from "../../../libs/react-mpk/services";
let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getTemplate = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/document/template`,
    query,
  });
};

service.getTemplateById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/document/template/${id}`,
  });
};

export default service;
