import { crud, http } from "../../libs/react-mpk/services";
let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getNotifications = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/notification`,
    query,
  });
};

export default service;
