import { crud, http } from "../../../libs/react-mpk/services";
let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getProductTemplate = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/dms/product`,
    query,
  });
};

service.getProductTemplateById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/dms/product/${id}`,
  });
};

service.uploadProduct = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/dms/product/add/yaml`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};

service.updateProduct = async (id, data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/dms/product/${id}`,
    data: http.objectToFormData(data),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};

service.updateJrmxlProduct = async (idProduct, idTemplate, file) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/dms/product/${idProduct}/template?templateId=${idTemplate}`,
    data: http.objectToFormData({ file: file }),
    config: { headers: { "content-type": "multipart/form-data" } },
  });
};

export default service;
