import { crud, http } from "../../../libs/react-mpk/services";
let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getStamping = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/document`,
    query,
  });
};

export default service;
