class summaryData{
  constructor(
    label     = 'string', 
    value     = 0, 
    unit      = null, 
    trend     = null, 
    trendUnit = null,
    hint      = null,
  ){
    this.label = label 
    this.value = value 
    this.unit = unit
    this.trend = trend 
    this.trendUnit = trendUnit
    this.hint = hint
  }
}

export default summaryData