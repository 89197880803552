/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { Button, DialogContainer, TextField, Card } from "react-md";
import { Dialog, DialogContent } from "@react-md/dialog";
// import './TFAPage.scss'
import Logo from "../Logo/Logo";
import { http, toast } from "../../services";
import { inject, observer } from "mobx-react";

import "./TFAPage.scss";
import LoaderInfo from "../LoaderInfo";

const TFAPage = ({ visible, onFinish, isTfa, ...props }) => {
  const textInputConfirm = useRef(null);
  const textInputVal = useRef(null);
  const [qr, setQr] = useState("");
  const [key, setKey] = useState("");
  const [isRecover, setIsRecover] = useState(false)
  const [qrRecover, setQrRecover] = useState(false)
  const [loading, setLoading] = useState(false)
  const getStatusTFA = props.authStore.getIsTFA();
  const access_token = props.authStore.getAccessToken();
  const { host } = props.envStore.env.sso;
  useEffect(() => {
    if (visible) {
      if (getStatusTFA === "true") {
        textInputVal.current.focus();
      }
      // http.get(
      //   `${host}/auth/tfa/recovery_code`,
      //   null,
      //   {
      //     withCredentials: true,
      //     headers: {
      //       authorization: `Bearer ${access_token}`,
      //     },
      //   },
      //   {
      //     useDefaultHost: false,
      //     useDefaultBaseUrl: false,
      //   }
      // );
    }
  }, [visible]);
  const handleReqQr = async () => {
    try {
      let qparams = {}
      if (isRecover) {
        qparams['key'] = key
      }
      let dataQR = await http.get(
        `${host}/auth/tfa/set`,
        qparams,
        {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        },
        {
          useDefaultHost: false,
          useDefaultBaseUrl: false,
        }
      );
      setQr(dataQR.data);
      setKey('')
      setQrRecover(true)
      textInputConfirm.current.focus();
    } catch (error) {
      toast.errorRequest(error);
    }
  };
  const handleConfirmQr = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true)
      if (isRecover && !qrRecover) {
        handleReqQr()
      } else {
        await http.get(
          `${host}/auth/tfa/${key}/${getStatusTFA === "true" && !qrRecover ? "validate" : "confirm"}`,
          null,
          {
            withCredentials: true,
            headers: {
              authorization: `Bearer ${access_token}`,
            },
          },
          {
            useDefaultHost: false,
            useDefaultBaseUrl: false,
          }
        );
        toast.success("Autentikasi berhasil");
        props.authStore.setIsTFA("DONE");
        onFinish();
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.errorRequest(error);
    }
  };
  const handleSkipTFA = async () => {
    props.authStore.setSkipTFA("TFA_SKIPED");
    onFinish();
  };
  const logout = () => {
    props.authStore.logout();
  };
  return (
    <Dialog
      id="simple-full-page-dialog"
      visible={visible}
      type="full-page"
      aria-labelledby="simple-full-page-dialog-title"
    >
      {loading ? <LoaderInfo children={'Proses data...'} />
        :
        <div className="mpk-flex fill direction-column align-center justify-center">
          <Logo
            logoStyle={{
              height: 55,
            }}
          />
          {getStatusTFA === "true" && !qrRecover && (
            <Card
              className="mpk-flex direction-column align-center mpk-margin-N margin-all mpk-padding-N padding-all"
              style={{
                maxWidth: 500,
              }}
            >
              <div className="mpk-flex mpk-margin-N bottom top">
                <div className="status-text text-body">
                  {`${isRecover ? 'Recover' : 'Kode Autentikasi'} (Two Factor Authentication)`}
                </div>
              </div>
              <p className="error-message mpk-body text-body mpk-dont-break-out">
                {
                  isRecover ? `Silahkan masukan KEY Recovery yang anda simpan.` : `Silahkan masukan kode autentikasi yang didapat di Google Authenticator.`
                }
              </p>
              <form
                className="flex mpk-flex direction-column mpk-full full-width"
                id="auth-form"
                onSubmit={handleConfirmQr}
                autocomplete={false}
              >
                <TextField
                  id="application-form-kode"
                  label={"Input Kode"}
                  className={`mpk-full full-width`}
                  ref={textInputVal}
                  value={key}
                  type='number'
                  onChange={(text) => {
                    setKey(text.target.value);
                  }}
                  min={0}
                  required
                />
                {isRecover ?
                  <div className="mpk-flex direction-row justify-end mpk-margin-N margin-top">
                    <Button
                      themeType="outline"
                      theme="warning"
                      className="mpk-margin-S right"
                      onClick={logout}
                      style={
                        {
                          // width: 200
                        }
                      }
                    >
                      Back To Login
                    </Button>
                    <Button
                      theme="primary"
                      themeType="contained"
                      // onClick={handleConfirmQr}
                      type="submit"
                      style={
                        {
                          // width: 200,
                        }
                      }
                    >
                      Submit
                    </Button>
                  </div>
                  :
                  <div className="mpk-flex direction-row justify-between mpk-margin-N margin-top">
                    <Button
                      themeType="outline"
                      theme="error"
                      className="mpk-margin-S right"
                      onClick={() => {
                        setIsRecover(true)
                        setKey("")
                      }}
                      style={
                        {
                          // width: 200
                        }
                      }
                    >
                      Recover
                    </Button>
                    <div className="mpk-flex direction-row">
                      <Button
                        themeType="outline"
                        theme="warning"
                        className="mpk-margin-S right"
                        onClick={logout}
                        style={
                          {
                            // width: 200
                          }
                        }
                      >
                        Back To Login
                      </Button>
                      <Button
                        theme="primary"
                        themeType="contained"
                        // onClick={handleConfirmQr}
                        type="submit"
                        style={
                          {
                            // width: 200,
                          }
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </div>

                }
              </form>
            </Card>
          )}

          {(!getStatusTFA || (isRecover && qrRecover)) && (
            <div className="mpk-flex direction-column align-center">
              {qr ? (
                <Card
                  className="mpk-margin-N margin-all mpk-padding-N padding-all"
                  style={{
                    maxWidth: 600,
                  }}
                >
                  <div className="flex mpk-flex direction-column align-center">
                    <div className="mpk-flex mpk-margin-N bottom top">
                      <div className="status-text text-body">
                        {"Kode Autentifikasi (Two Factor Authentication)"}
                      </div>
                    </div>
                    <p className="error-message mpk-body text-body mpk-dont-break-out">
                      {
                        "Silahkan Scan QR CODE ini di Google Authenticator dan masukan kode autentikasi yang didapat."
                      }
                    </p>
                    <img
                      src={qr}
                      style={{
                        width: 300,
                        height: 300,
                      }}
                    />
                    <div className="flex mpk-flex direction-row align-center">
                      <form
                        className="flex mpk-flex direction-column align-end mpk-full full-width"
                        id="auth-form"
                        onSubmit={handleConfirmQr}
                        autocomplete={false}
                      >
                        <TextField
                          id="application-form-kode"
                          label={"Input Kode"}
                          className={`mpk-full full-width`}
                          ref={textInputConfirm}
                          value={key}
                          type='number'
                          min={0}
                          onChange={(text) => {
                            setKey(text.target.value);
                          }}
                          required
                        />
                        <div className="mpk-flex direction-row mpk-margin-N margin-top">

                          <Button
                            themeType="outline"
                            theme="warning"
                            className="mpk-margin-S right"
                            onClick={logout}
                            style={
                              {
                                // width: 200
                              }
                            }
                          >
                            Back To Login
                          </Button>
                          <Button
                            theme="primary"
                            themeType="contained"
                            // onClick={handleConfirmQr}
                            type="submit"
                            style={
                              {
                                // width: 200,
                              }
                            }
                          >
                            Submit
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Card>
              ) : (
                <div className="flex body-content mpk-flex direction-column">
                  <div className="mpk-flex mpk-margin-N bottom top">
                    <div className="status-text text-body">
                      {"Apakah anda akan mengaktifkan Two Factor Authentication?"}
                    </div>
                  </div>
                  <p className="error-message mpk-body text-body mpk-dont-break-out">
                    {"Pilih YA jika ingin mengaktifkan Two Factor Authentication"}
                  </p>
                  <div className="mpk-margin-N top mpk-flex">
                    <Button
                      theme="error"
                      themeType="contained"
                      className="mpk-margin-S right"
                      onClick={() => handleReqQr()}
                    >
                      Ya
                    </Button>
                    <Button
                      themeType="outline"
                      // theme='warning'
                      className="mpk-margin-S right"
                      onClick={handleSkipTFA}
                    >
                      Tidak
                    </Button>
                    <span className="flex" />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      }
    </Dialog>
  );
};

export default inject("modalStore", "authStore", "envStore")(observer(TFAPage));
