import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.extend(utc);

const convertUtcToCurrentUserTime = (date) => {
  let localDateString = "";
  if (date) {
    const utcDate = dayjs.utc(date);
    const localDate = utcDate.local();
    localDateString = localDate.format("DD-MM-YYYY HH:mm:ss");
  }

  return localDateString;
};

export default convertUtcToCurrentUserTime;
