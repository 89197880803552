import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import Script from "react-load-script";
import routes from "./routes";
import "./App.scss";
import { init } from "./libs/react-mpk/hocs";
import qs from "query-string";

const App = ({ envStore, navigationStore, authStore }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!checked && window.location.pathname === "/") {
      navigationStore.redirectTo(`/${envStore.env.applicationType}/dashboard`);
      setChecked(true);
    }
  }, [checked]);

  return (
    <div className="App mpk-full viewport-height">
      {Object.keys(envStore.env.widgetInterface).map((key) => (
        <Script
          key={`widget-interface-${key}`}
          url={envStore.env.widgetInterface[key].url}
        />
      ))}
      <Router
        history={navigationStore.browserHistory}
        className="mpk-position relative"
      >
        {routes()}
      </Router>
      {/* {envStore.kbs && envStore.kbs.length > 0 ? (
        <SupportMpk appName={appInfo.name} kbs={kbs} />
      ) : null} */}
    </div>
  );
};

export default init()(App);
