import React, { useState } from 'react'
import { FormWrapper } from '../../../../../libs/react-mpk/wrapper'
import { DataForm, DividerLabel, DataTable } from '../../../../../libs/react-mpk/components';
import {
  TextField,
} from "@react-md/form";
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { Button } from 'react-md';

const ProfileFeature = ({ envStore }) => {
  return (
    <FormWrapper
      // actions={[
      //   {
      //     label: 'Reload',
      //     iconClassName: 'mdi mdi-reload'
      //   }
      // ]}
      baseId="mod-form-profile"
      title={''}
      submitLabel={t("column.simpan")}
      defaultData={{
        "name": "",
        "description": "",
        "code": "",
        "url": "",
        "status": true,
        "hostPermission": false,
      }}
      definitions={[
        {
          render: (
            <DividerLabel className='mpk-margin-N margin-top'>
              {t("column.general")}
            </DividerLabel>
          )
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.column.name"),
          key: 'name'
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.column.description"),
          key: 'description',
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.column.code"),
          key: 'code',
        },
        {
          inputType: DataForm.inputTypes.CHECKBOX,
          label: t("modules.product.column.hostPermission"),
          key: 'hostPermission',
          width: '50%'
        }
      ]}

      onChange={(data, key, value) => {
        return data
      }}
      onSubmit={(data, callback) => {
        console.log(data);
        setTimeout(callback, 2000)
      }}
    />
  )
}

export default inject('envStore')(observer(ProfileFeature))
