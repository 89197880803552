import React from 'react'
import { inject, observer } from 'mobx-react'
import * as constant from '../../config/constant'
import t from 'counterpart'
import moment from 'moment'
import { DropdownMenu, BOTTOM_CENTER_ANCHOR, FontIcon } from 'react-md'

const Footer = ({envStore}) => {
  return (
    <div className="mpk-sidebar-footer mpk-border dark solid border-top mpk-padding-N padding-left padding-right mpk-flex justify-between align-center mpk-font size-NS">
      <div>&copy; {`${moment(envStore.env.timestamp).format('YYYY')} ${constant.portals[envStore.env.theme].name}`}</div>
      {envStore.env.kbs.length > 0 && (
        <DropdownMenu
          id="sidebar-footer-support-center"
          className="btn-support-center"
          anchor={BOTTOM_CENTER_ANCHOR}
          disableDropdownIcon
          items={[...envStore.env.kbs.map(d => ({
            children: d.translate ? t.translate(d.label) : d.label,
            leftAddon: <FontIcon iconClassName={d.type === 'documentation' ? `mdi mdi-file-document` : 'mdi mdi-book-search'}/>,
            onClick: () => {
              if(window.kbsWidget) window.kbsWidget[d.type](d.code)
              else console.warn('[react-mpk][component][sidebar] - The KBS script interface has not been loaded yet')
            }
          })), ...[
            {role: 'separator', inset: false},
            {
              children: 'Forum',
              leftAddon: <FontIcon iconClassName="mdi mdi-forum"/>,
              onClick: () => {
                window.open(envStore.portal.forumUrl, '_blank')
              }
            },
            {
              children: t.translate('mpk.column.contactInfo'),
              leftAddon: <FontIcon iconClassName="mdi mdi-phone"/>,
              onClick: () => {
                const w = 560;
                const h = 640;
                const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
                const left = ((width / 2) - (w / 2)) + dualScreenLeft;
                const top = ((height / 2) - (h / 2)) + dualScreenTop;   
                window.open(`https://pajakku.com/contact`, 'Contact Pajakku', 'width='+w+', height='+h+', top='+top+',left='+left)
              }
            }
          ]]}
          themeType="flat"
          disableRipple
          style={{
            minHeight: 'unset'
          }}
        >
          <span className="mpk-font size-S mpk-link">
            {t.translate('mpk.column.supportCenter')}
          </span>
        </DropdownMenu>
      )}
    </div>
  )
}

export default inject('envStore')(observer(Footer))