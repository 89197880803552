import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./ReferalCode.service";
import { FontIcon, Button, Chip, List, ListItem } from "react-md";
import ErrorService from "../../services/errorService";
import { format } from "../../libs/react-mpk/services/number.service";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { DataForm } from "../../libs/react-mpk/components";
import LoadingOverlay from "react-loading-overlay";
import ReferalCodeForm from "./ReferalCodeForm";
import iziToast from "izitoast";

const ReferalCode = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
  ...props
}) => {
  const basePath = "master-referal-code";
  const baseId = "mod-referal-code";
  const { inputTypes, definition } = FormWrapper;
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [fetchParam, setFetchParam] = useState([]);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  let [search, setSearch] = useState({});
  const [showForm, setShowForm] = useState(false);

  const deleteById = async (item) => {
    modalStore.showConfirm({
      title: t.translate("word.delete"),
      children: t.translate("word.askConfirm"),
      onSubmit: async (callback) => {
        setPloading({ loading: true, message: t.translate("word.prosesData") });
        try {
          var proses = t.translate("word.delete");
          await service.deleteReferalCodeById(item.id);
          iziToast.success({
            title: proses,
            message: t.translate("word.prosesDone"),
          });
          setPloading({ loading: false, message: "" });
          document.getElementsByClassName("mdi-reload")[0].click();
          callback();
        } catch (e) {
          setPloading({ loading: false, message: "" });
          callback();
          ErrorService(e);
        }
      },
    });
  };

  var actions = [
    {
      label: `${t.translate("mpk.column.add")}`,
      iconClassName: "mdi mdi-plus",
      onClick: async () => {
        setShowForm(true);
      },
    },
  ];

  var itemActions = [
    new TableWrapper.action(
      t.translate("word.view"),
      "mdi mdi-eye",
      async (item) => {
        modalStore.showInfo({
          title: `Detail`,
          dialogStyle: { width: 1000 },
          children: (
            <>
              <List>
                <ListItem
                  primaryText={t.translate("column.codeReferral")}
                  secondaryText={item.code}
                />
                <ListItem
                  primaryText={t.translate("column.message")}
                  secondaryText={item.message}
                />
                <ListItem
                  primaryText={t.translate("column.createdDate")}
                  secondaryText={item.createdDate}
                />
                <ListItem
                  primaryText={t.translate("column.createdBy")}
                  secondaryText={item.createdBy}
                />
                <ListItem
                  primaryText={t.translate("column.updatedBy")}
                  secondaryText={
                    item.lastModifiedBy ? item.lastModifiedBy : "-"
                  }
                />
                <ListItem
                  primaryText={t.translate("column.updatedAt")}
                  secondaryText={
                    item.lastModifiedDate ? item.lastModifiedDate : "-"
                  }
                />
              </List>
            </>
          ),
        });
      },
      true
    ),
    new TableWrapper.action(
      t.translate("word.edit"),
      "mdi mdi-pencil",
      async (item) => {
        setSelectedItem(item);
        setShowForm(true);
      },
      true
    ),
    new TableWrapper.action(
      t.translate("column.delete"),
      "mdi mdi-delete",
      async (item) => {
        deleteById(item);
      }
    ),
  ];

  const onCloseForm = () => {
    setShowForm(false);
    setSelectedItem(null);
    document.getElementsByClassName("mdi-reload")[0].click();
  };

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
        <TableWrapper
          selectable={false}
          useFilter={false}
          baseId={baseId}
          title={t.translate(`modules.${basePath}.title`)}
          className={className}
          commandbar={{
            style: {
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: 15,
            },
          }}
          defaultData={[]}
          defaultSortBy="date"
          sideHeader={
            <DataForm
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName="mdi mdi-magnify"
              submitLabel={`${t.translate("word.search")}`}
              additionalAction={[
                {
                  show: true,
                  render: () => (
                    <Button
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        setSearch({});
                        localStorage.removeItem(`${baseId}-saved-search`);
                        setTimeout(() => {
                          document
                            .getElementsByClassName("mdi-reload")[0]
                            .click();
                        }, 1000);
                      }}
                    >
                      {t.translate("word.delete")}
                    </Button>
                  ),
                },
              ]}
              onSubmit={(values, callback) => {
                if (values["createdDate.greaterOrEqualThan"])
                  values["createdDate.greaterOrEqualThan"] = new Date(
                    values["createdDate.greaterOrEqualThan"]
                  );
                if (values["createdDate.lessOrEqualThan"])
                  values["createdDate.lessOrEqualThan"] = new Date(
                    values["createdDate.lessOrEqualThan"]
                  );
                setSearch(values);
                localStorage.setItem(
                  `${baseId}-saved-search`,
                  JSON.stringify(search)
                );
                setTimeout(() => {
                  document.getElementsByClassName("mdi-reload")[0].click();
                  callback("", false, false, false);
                }, 1000);
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div
                        className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                      >
                        <p>{t.translate(`word.searching`)}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.codeReferral`
                  )}`,
                  key: "code.contains",
                  type: "text",
                },
              ]}
            />
          }
          columns={[
            {
              label: t.translate("column.codeReferral"),
              searchable: true,
              sortable: true,
              key: "code",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.code,
            },
            {
              label: t.translate("column.message"),
              searchable: true,
              sortable: true,
              key: "message",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.message,
            },
            {
              label: t.translate("column.createdDate"),
              searchable: true,
              sortable: true,
              key: "createdDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.createdDate,
            },
            {
              label: t.translate("column.createdBy"),
              searchable: true,
              sortable: true,
              key: "createdBy",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.createdBy,
            },
            {
              label: t.translate("column.updatedAt"),
              searchable: true,
              sortable: true,
              key: "lastModifiedDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.lastModifiedDate,
            },
            {
              label: t.translate("column.updatedBy"),
              searchable: true,
              sortable: true,
              key: "lastModifiedBy",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.lastModifiedBy,
            },
          ]}
          actions={actions}
          itemActions={itemActions}
          onFetchData={(params) => {
            return new Promise(async (resolve, reject) => {
              try {
                params = {
                  ...search,
                  ...params,
                  sort: "createdDate,DESC",
                };
                if (params.search) {
                  params.size = 9999;
                }
                var getPage = null;

                getPage = await service.getReferalCode(params);

                setFetchParam(params);
                setData(getPage.data);
                getPage.headers["x-pagination-count"] =
                  getPage.headers["x-total-count"];
                resolve(getPage);
              } catch (e) {
                setFetchParam(params);
                resolve();
                ErrorService(e);
              }
            });
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={true}
        />
      </LoadingOverlay>
      <ReferalCodeForm
        visible={showForm}
        onRequestClose={() => {
          onCloseForm();
        }}
        defaultData={selectedItem}
      />
    </>
  );
};

export default inject("envStore", "modalStore")(observer(ReferalCode));
