import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../../../libs/react-mpk/wrapper'
import { Link } from '../../../../libs/react-mpk/components'
import t from 'counterpart'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { toast } from '../../../../libs/react-mpk/services'
import FormFeatures from './FormFeatures'
import { useParams } from "react-router-dom";

let baseId = "mod-table-features"
const TableService = ({
  className = '',
  showCommandbar = true,
  temporaryStore,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState(null)
  const [showAdd, setShowAdd] = useState(false)
  console.log("🚀 ~ file: TableResources.js ~ line 26 ~ showAdd", showAdd)
  let { tab } = useParams()

  return (
    <>
      <TableWrapper
        baseId={baseId}
        className={className}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultCollapse={true}
        defaultSortBy="createdDate"
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: t("modules.product.column.name"),
            searchable: false,
            key: 'name',
            render: item => (<Link
              style={{
                cursor: 'pointer'
              }}
              to={`/internal/service_detail/${item.id}/${tab}`}
            >
              {item.name}
            </Link>)
          },
          {
            label: t("modules.product.column.code"),
            searchable: true,
            sortable: true,
            key: 'code',
            definition: {
              inputType: TableWrapper.inputTypes.INPUT,
              criterias: ['contains', 'equals', 'notEquals', 'in']

            },
            render: item => (item.code)
          },
          {
            label: t("column.createdAt"),
            searchable: true,
            sortable: true,
            key: "created_by",
            type: TableWrapper.dataTypes.INPUT,
            render: (item) => item.created_by,
          },
          {
            label: t("column.createdBy"),
            searchable: true,
            sortable: true,
            key: "created_at",
            type: TableWrapper.dataTypes.DATE,
            render: (item) => moment(item.created_at).format("lll"),
          },
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            async (item) => {
              setSelectedItem(item)
              setShowAdd(true)
            },
            true,
            // 'PUT:/template/{id}'
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              props.modalStore.showConfirm({
                title: "Delete",
                children: t("word.hapusInfo").replace("*module", "Resource"),
                onSubmit: async (callback) => {
                  try {
                    // await deleteTemplate(item.id);
                    TableWrapper.reload(baseId);
                    callback();
                    toast.success(t("word.hapusSuccess").replace("*module", "Resource"));
                  } catch (error) {
                    toast.errorRequest(error);
                    callback();
                  }
                },
              });
            },
            true,
            // 'DELETE:/template/{id}'
          ),
        ]}
        actions={[
          new TableWrapper.action(
            'Add',
            'mdi mdi-plus',
            () => {
              setSelectedItem(null)
              setShowAdd(true)
            },
            true
          )
        ]}
        onFetchData={async query => {
          // return service.getAllService(query, isAdmin)
          return { data: [] }
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <FormFeatures
        baseId={baseId}
        visible={showAdd}
        onRequestClose={() => setShowAdd(false)}
        item={selectedItem}
      />
    </>
  )
}

export default inject('navigationStore', 'modalStore', 'authStore', 'envStore', 'temporaryStore')(observer(TableService))

