import React, { useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./ProductTemplate.service";
import { FontIcon, Button, Chip, List, ListItem } from "react-md";
import ErrorService from "../../../services/errorService";
import { format } from "../../../libs/react-mpk/services/number.service";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { DataForm } from "../../../libs/react-mpk/components";
import LoadingOverlay from "react-loading-overlay";
import { convertUtcToCurrentUserTime } from "../../../utilities";
import { find } from "lodash";
import ProductTemplateForm from "./ProductTemplateForm";
import ProductTemplateUpdateForm from "./ProductTemplateUpdateForm";
import ProductTemplateUpdateJrmxl from "./ProductTemplateUpdateJrmxlForm";

const ProductTemplate = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
  ...props
}) => {
  const basePath = "template-document/product";
  const baseId = "mod-template-document-product";
  const { inputTypes, definition } = FormWrapper;
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [fetchParam, setFetchParam] = useState([]);
  const [data, setData] = useState([]);
  let [search, setSearch] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUpdateJrmxlForm, setShowUpdateJrmxlForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const cities = [
    { id: 1, name: "Jakarta" },
    { id: 2, name: "Bandung" },
    { id: 3, name: "Semarang" },
    { id: 4, name: "Yogyakarta" },
    { id: 5, name: "Surabaya" },
  ];

  var actions = [
    {
      label: `${t.translate("mpk.column.add")}`,
      iconClassName: "mdi mdi-plus",
      onClick: async () => {
        setShowForm(true);
      },
    },
  ];

  var itemActions = [
    new TableWrapper.action(
      t.translate("word.view"),
      "mdi mdi-eye",
      async (item) => {
        modalStore.showInfo({
          title: `Detail`,
          dialogStyle: { width: 1000 },
          children: (
            <>
              <List>
                <ListItem primaryText="Title" secondaryText={item.title} />
                <ListItem primaryText="Alias" secondaryText={item.alias} />
                <ListItem
                  primaryText="Deskripsi"
                  secondaryText={item.description}
                />
                <ListItem
                  primaryText="Price"
                  secondaryText={`Rp. ${format(item.price)}`}
                />
                <ListItem
                  primaryText="Created By"
                  secondaryText={item.createdBy}
                />
                <ListItem
                  primaryText="Created Date"
                  secondaryText={item.createdDate}
                />
                <ListItem
                  primaryText="Modify By"
                  secondaryText={
                    item.lastModifiedBy ? item.lastModifiedBy : "-"
                  }
                />
                <ListItem
                  primaryText="Modify Date"
                  secondaryText={
                    item.lastModifiedDate ? item.lastModifiedDate : "-"
                  }
                />
              </List>
            </>
          ),
        });
      },
      true
    ),
    new TableWrapper.action(
      t.translate("word.edit"),
      "mdi mdi-pencil",
      async (item) => {
        setSelectedItem(item);
        setShowUpdateForm(true);
      },
      true
    ),
    new TableWrapper.action(
      "Update .jrmxl",
      "mdi mdi-file",
      async (item) => {
        setSelectedItem(item);
        setShowUpdateJrmxlForm(true);
      },
      true
    ),
  ];

  const onCloseForm = () => {
    setShowForm(false);
    setShowUpdateForm(false);
    setShowUpdateJrmxlForm(false);
    setSelectedItem(null);
    document.getElementsByClassName("mdi-reload")[0].click();
  };

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
        <TableWrapper
          selectable={false}
          useFilter={false}
          baseId={baseId}
          title={"Template Document - Product"}
          className={className}
          commandbar={{
            style: {
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: 15,
            },
          }}
          defaultData={[]}
          defaultSortBy="date"
          sideHeader={
            <DataForm
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName="mdi mdi-magnify"
              submitLabel={`${t.translate("word.search")}`}
              additionalAction={[
                {
                  show: true,
                  render: () => (
                    <Button
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        setSearch({});
                        localStorage.removeItem(`${baseId}-saved-search`);
                        setTimeout(() => {
                          document
                            .getElementsByClassName("mdi-reload")[0]
                            .click();
                        }, 1000);
                      }}
                    >
                      {t.translate("word.delete")}
                    </Button>
                  ),
                },
              ]}
              onSubmit={(values, callback) => {
                if (values["createdDate.greaterOrEqualThan"])
                  values["createdDate.greaterOrEqualThan"] = new Date(
                    values["createdDate.greaterOrEqualThan"]
                  );
                if (values["createdDate.lessOrEqualThan"])
                  values["createdDate.lessOrEqualThan"] = new Date(
                    values["createdDate.lessOrEqualThan"]
                  );
                setSearch(values);
                localStorage.setItem(
                  `${baseId}-saved-search`,
                  JSON.stringify(search)
                );
                setTimeout(() => {
                  document.getElementsByClassName("mdi-reload")[0].click();
                  callback("", false, false, false);
                }, 1000);
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div
                        className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                      >
                        <p>{t.translate(`word.searching`)}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.title`
                  )}`,
                  key: "title.contains",
                  type: "text",
                },
              ]}
            />
          }
          columns={[
            {
              label: t.translate("column.title"),
              searchable: true,
              sortable: true,
              key: "title",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.title,
            },
            {
              label: t.translate("column.alias"),
              searchable: true,
              sortable: true,
              key: "alias",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.alias,
            },
            {
              label: t.translate("column.free"),
              key: "free",
              sortable: true,
              searchable: true,
              render: (d) => {
                return (
                  <Chip
                    className="chip-error"
                    style={{ backgroundColor: "#7096F0", color: "white" }}
                  >
                    {d.free ? "Free" : "Non Free"}
                  </Chip>
                );
              },
            },
            {
              label: t.translate("column.price"),
              searchable: true,
              sortable: true,
              key: "price",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => `Rp. ${format(item.price)}`,
            },
            {
              label: t.translate("column.createdDate"),
              searchable: true,
              sortable: true,
              key: "createdDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.createdDate,
            },
            {
              label: t.translate("column.createdBy"),
              searchable: true,
              sortable: true,
              key: "createdBy",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.createdBy,
            },
          ]}
          actions={actions}
          itemActions={itemActions}
          onFetchData={(params) => {
            return new Promise(async (resolve, reject) => {
              try {
                params = {
                  ...search,
                  ...params,
                  sort: "createdDate,ASC",
                };
                if (params.search) {
                  params.size = 9999;
                }
                var getPage = null;

                getPage = await service.getProductTemplate(params);

                setFetchParam(params);
                setData(getPage.data);
                getPage.headers["x-pagination-count"] =
                  getPage.headers["x-total-count"];
                resolve(getPage);
              } catch (e) {
                setFetchParam(params);
                resolve();
                ErrorService(e);
              }
            });
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={true}
        />
      </LoadingOverlay>
      <ProductTemplateForm
        visible={showForm}
        onRequestClose={() => {
          onCloseForm();
        }}
        item={selectedItem}
      />
      <ProductTemplateUpdateForm
        visible={showUpdateForm}
        onRequestClose={() => {
          onCloseForm();
        }}
        item={selectedItem}
      />
      <ProductTemplateUpdateJrmxl
        visible={showUpdateJrmxlForm}
        onRequestClose={() => {
          onCloseForm();
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("envStore", "modalStore")(observer(ProductTemplate));
