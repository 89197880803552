import axios from "axios";
import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getReportPaid = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/log/export`,
    query,
  });
};

service.downloadById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/log/export/download/${id}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};

service.downloadBulk = async (data) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/log/export/download?ids=${data.join(",")}`,
    config: {
      responseType: "arraybuffer",
    },
  });
};

service.deleteById = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/log/export/${id}`,
  });
};

service.deleteBulk = async (data) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/log/export?ids=${data.join(",")}`,
  });
};

service.createReportPaid = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/topup/report-paid`,
    data: data,
  });
};

export default service;
