import React, {useState, useEffect} from 'react'
import LoaderInfo from '../LoaderInfo'
import NotFoundData from '../NotFoundData'
import t from 'counterpart'
import { toast } from '../../services'
import { groupBy, merge, sortBy, cloneDeep } from 'lodash'
import { eventNames } from '../../config/constant'
import Plot from 'react-plotly.js';
import Hint from '../Hint'

const Chart = ({
  className     = '',
  label         = null,
  onFetchData   = null,
  onInitData    = () => (new Promise(resolve => resolve([]))),
  chartConfig   = {},
  dataConfig    = {},
  hintMessage       = null,
  hintShowIcon      = true,
  hintIconClassName = "mdi mdi-information",
  hintMore          = null,
  swapHintPosition  = false,
  onReload          = null,
  query             = {},
  getHandleFetchData = null,
  staticOptions      = true
}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [plotlyConfig, setPlotlyConfig] = useState(merge({
    layout:{
      title: label,
      autosize: true,
      height: 480,
    }
  }, chartConfig))

  const handleFetchData = async (__query = query) => {
    if(onFetchData){
      setLoading(true)
      try {
        dataConfig = null ? {} : dataConfig
        let res = await onFetchData(__query)
        let initData = onInitData ? await onInitData(res.data, __query) : res.data 
        let __data = []
        if(Array.isArray(initData[0])){
          for(let idx = 0 ; idx < initData[0].length ; idx++){
            for(let i = 0 ; i < initData.length ; i++){
              __data[idx] = __data[idx] || []
              __data[idx].push(initData[i][idx])
            }
          }
        } else {
          __data.push(initData)
        }
        
        let finalData = []
        
        for(let a of __data){
          let __dataConfig = Array.isArray(initData[0]) ? (a[0].dataConfig || dataConfig ) : dataConfig
          let groupByCategory = groupBy(a, d => (d.category))

          for(let key of Object.keys(groupByCategory)){
            let group = merge(cloneDeep(__dataConfig), {
              x: [],
              y: [],
              name: key
            })

            groupByCategory[key] = sortBy(groupByCategory[key], (d) => (d.key))
            for(let item of groupByCategory[key]){
              group.x.push(item.key)
              group.y.push(item.value)
            }

            if(__dataConfig.type === 'pie') {
              group.labels = group.x;
              group.values = group.y;
            }

            finalData.push(group)
          }
        }

        setData(finalData)
        setLoading(false)
      } catch (error) {
        // setLoading(false)
        toast.errorRequest(error)
      }
    }
  }

  useEffect(() => {
    if(getHandleFetchData) {
      getHandleFetchData(handleFetchData)
      handleFetchData()
    }
  }, [])

  useEffect(() => {
    if(staticOptions){
      handleFetchData()
    }
  }, [query])

  return (
    <div 
      className={`mpk-view-data-chart mpk-paper mpk-full full-width ${className}`}
    >
      {!swapHintPosition && hintMessage && (
          <Hint
            className="mpk-full full-height"
            showIcon={hintShowIcon}
            iconClassName={hintIconClassName}
            more={hintMore}
          >
            {hintMessage}
          </Hint>
      )}
      {loading && !data ? <LoaderInfo>{t.translate('mpk.sentence.loadingData')}</LoaderInfo> : (
        data.length > 0 ? (
          <Plot
            className="mpk-full full-width"
            data={data}

            {...plotlyConfig}
          />
        ) : (
          <NotFoundData 
            className="mpk-margin-M margin-top margin-bottom"
            headline={t.translate('mpk.sentence.noChartData').replace('$label', label)}
          />
        )
      )}
    </div>
  )
}

export default Chart
