import React, { useState } from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import { DataForm, DividerLabel, DataTable } from '../../../libs/react-mpk/components';
import {
  TextField,
} from "@react-md/form";
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'
import { Button } from 'react-md';

const cities = [
  { id: 1, name: 'Jakarta' },
  { id: 2, name: 'Bandung' },
  { id: 3, name: 'Semarang' },
  { id: 4, name: 'Yogyakarta' },
  { id: 5, name: 'Surabaya' }
]

const Profile = ({ envStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [dataBackend, setDataBackend] = useState([])
  const _handleInputBackendServer = (event, i, field) => {
    setDataBackend(e => {
      let draf = [...e]
      draf[i][field] = event.target.value
      return draf
    })
  }
  const _handleDeleteBackendServer = (i) => {
    setDataBackend(e => {
      let draf = [...e]
      draf.splice(i, 1)
      return draf
    })
  }
  const _handleAddBackendServer = (i) => {
    setDataBackend(e => {
      let draf = [...e]
      draf.push(
        {
          name: '',
          host: '',
          baseUrl: ''
        }
      )
      return draf
    })
  }
  return (
    <FormWrapper
      // actions={[
      //   {
      //     label: 'Reload',
      //     iconClassName: 'mdi mdi-reload'
      //   }
      // ]}
      baseId="mod-form-profile"
      title={''}
      submitLabel={t("modules.product.tab.credential.generate")}
      submitIconClassName=''
      defaultData={{
        "clientId": "",
        "clientSecret": "",
        "requirCaptcha": false,
      }}
      definitions={[
        {
          inputType: DataForm.inputTypes.DIVIDER,
          label: t("modules.product.tab.credential.notes")
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.tab.credential.cliendId"),
          key: 'clientId',
          readOnly: true
        },
        {
          inputType: DataForm.inputTypes.INPUT,
          label: t("modules.product.tab.credential.clientSecret"),
          key: 'clientSecret',
          readOnly: true,
        },
        {
          inputType: DataForm.inputTypes.CHECKBOX,
          label: t("modules.product.tab.credential.requirCaptcha"),
          key: 'requirCaptcha',
          width: '50%',
          readOnly: true
        },
      ]}
      onChange={(data, key, value) => {
        return data
      }}
      onSubmit={(data, callback) => {
        console.log(data);
        setTimeout(callback, 2000)
      }}
    />
  )
}

export default inject('envStore')(observer(Profile))