import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import service from "./paid.service";
import { cloneDeep } from "lodash";
import {
  toastError,
  toastSuccess,
} from "../../../libs/react-mpk/services/sweetToast.service";

const defaultData = () => ({
  // string: "",
  // number: 0,
  // boolean: false,
  // multiCheckbox: [""],
});

const PaidReportForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const basePath = "template-document/product";
  const baseId = "mod-template-document-product-form";
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  // useEffect(() => {
  // 	if (visible) {
  // 		let selectedItem = defaultData();
  // 		if (props.item) {
  // 			selectedItem = cloneDeep(props.item)
  // 			selectedItem.number = String(selectedItem.number)
  // 		}
  // 		setItem(selectedItem)
  // 	} else {
  // 		setItem(defaultData())
  // 	}
  // 	setReady(visible)
  // }, [visible])

  return (
    ready && (
      <DataForm
        baseId={baseId}
        title="Upload Product"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.DATEPICKER,
            label: "Tanggal Awal",
            key: "tanggalAwal",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.DATEPICKER,
            label: "Tanggal Akhir",
            key: "tanggalAkhir",
            required: true,
          },
        ]}
        onBeforeChange={(key, value) => {
          // console.log(key, value);
          return value;
        }}
        onSubmit={async (data, callback) => {
          // console.log(data);
          try {
            let res = await service.createReportPaid(data);
            console.log(res.data, "res.data");
            callback("success", false);
          } catch (error) {
            callback(error, true, false);
          }
        }}
        // style={{ height: 500 }}
      />
    )
  );
};

export default PaidReportForm;
