import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import service from "./ReferalCode.service";
import serviceLogExport from "../../LogExport/logExport.service";
import t from "counterpart";
import downloadjs from "downloadjs";
import iziToast from "izitoast";

const ReferalCodeReport = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const baseId = "mod-report-referal-code";
  const basePath = "report-referal-code";

  return (
    <DataForm
      baseId={baseId}
      title={t.translate(`column.export`)}
      visible={visible}
      defaultData={{}}
      onRequestClose={onRequestClose}
      asDialog={true}
      style={{ marginBottom: 100 }}
      definitions={[
        {
          inputType: DataForm.inputTypes.INPUT,
          label: "Kode Referal",
          key: "referalCode",
        },
        {
          inputType: DataForm.inputTypes.DATEPICKER,
          label: "Tanggal Awal",
          key: "tanggalAwal",
          required: true,
        },
        {
          inputType: DataForm.inputTypes.DATEPICKER,
          label: "Tanggal Akhir",
          key: "tanggalAkhir",
          required: true,
        },
      ]}
      onBeforeChange={(key, value) => {
        // console.log(key, value);
        return value;
      }}
      onSubmit={async (data, callback) => {
        const tanggalAwal = new Date(data.tanggalAwal);
        const tanggalAkhir = new Date(data.tanggalAkhir);

        if (tanggalAwal > tanggalAkhir) {
          iziToast.info({
            title: t.translate("word.validation"),
            message: t.translate("word.tanggalAwalTidakBolehAwal"),
          });
          callback({}, true, false);
        } else {
          try {
            let res = await service.createReferalCodeReport(data);
            // const getFile = await serviceLogExport.downloadById(res.data.id);
            // downloadjs(getFile.data, getFile.headers.filename);
            // downloadjs(getFile.data, `${data.referalCode}.xls`);
            iziToast.success({
              title: t.translate("word.download"),
              message: t.translate("word.instructionDownload"),
            });
            callback("success", false);
          } catch (error) {
            callback(error, true, false);
          }
        }
      }}
    />
  );
};

export default ReferalCodeReport;
