import React from 'react'
import { Commandbar, Flex, Link, LoaderInfo } from '../components'
import DataForm from '../components/DataForm'
import { inputTypes } from '../config/constant'
import { Button, FontIcon } from 'react-md'

const FormWrapper = ({
  actions = [],
  baseId = 'mpk-form-wrapper-id',
  className = '',
  backTo = null,
  hintMessage = '',
  hintShowIcon = true,
  hintIconClassName = 'mdi mdi-information',
  hintMore = null,
  title = '',
  definitions = [],
  onBeforeChange = null,
  onSubmit = null,
  showCommandbar = true,
  loading = false,
  tableForm = false, // Custom Unifikasi
  customRules = null,  // Custom Rules,
  defaultData = {},
  submitLabel = null,
  editable = true,
  commandbar = {},
  actionType = null,
  limitActionType = 2,
  ...props
}) => {

  return (
    <Flex
      className={`mpk-form-wrapper mpk-full full-width ${className}`}
      direction={Flex.properties.direction.COLUMN}
      fit
    >
      {showCommandbar && (
        <Commandbar
          actions={actions}
          title={title}
          actionType={actionType}
          limitActionType={limitActionType}
          leftCorner={backTo ? (
            <Link to={backTo}>
              <Button
                className="mpk-margin-NS margin-right"
                buttonType="icon"
              >
                <FontIcon iconClassName="mdi mdi-arrow-left" />
              </Button>
            </Link>
          ) : null}
          {...commandbar}
        />
      )}
      {loading ? <LoaderInfo /> :
        <DataForm
          baseId={baseId}
          tableForm={tableForm}     // Custom Unifikasi
          customRules={customRules} // Custom Rules
          definitions={definitions}
          onSubmit={onSubmit}
          onBeforeChange={onBeforeChange}
          defaultData={defaultData}
          className="mpk-animation slide-in"
          submitLabel={submitLabel}
          {...{ hintMessage, hintShowIcon, hintIconClassName, hintMore }}
          {...props}
          editable={editable}
        />
      }
    </Flex>
  )
}

FormWrapper.inputTypes = inputTypes
FormWrapper.formatTypes = DataForm.formatTypes
FormWrapper.definition = DataForm.definition
FormWrapper.LoadingButton = DataForm.LoadingButton
FormWrapper.reset = DataForm.reset
FormWrapper.submit = DataForm.submit

export default FormWrapper
