import { crud, http } from "../../libs/react-mpk/services";
let service = new crud("/");

service.fetchData = async () => {
  return http.request({
    method: http.methods.GET,
    url: `/v1/account/me`,
    // config: defaultConfig,
  });
};

export default service;
