import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import service from "./ReferalCode.service";
import t from "counterpart";
import { inputTypes } from "../../libs/react-mpk/config/constant";

const ReferalCodeForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const basePath = "master-referal-code";
  const baseId = "mod-referal-code-form";

  return (
    <DataForm
      baseId={baseId}
      title={t.translate(`modules.${basePath}.create`)}
      visible={visible}
      onRequestClose={onRequestClose}
      asDialog={true}
      defaultData={props.defaultData ?? {}}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`column.codeReferral`),
          key: "code",
          type: "text",
          required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`column.message`),
          key: "message",
          type: "text",
          required: true,
        },
      ]}
      onBeforeChange={(key, value) => {
        return value;
      }}
      onSubmit={async (data, callback) => {
        try {
          if (props.defaultData) {
            await service.updateReferalCode(data.id, data);
          } else {
            await service.createReferalCode(data);
          }
          callback("success", false);
        } catch (error) {
          callback(error, true, false);
        }
      }}
    />
  );
};

export default ReferalCodeForm;
