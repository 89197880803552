import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import service from "./ProductTemplate.service";
import { cloneDeep } from "lodash";
import {
  toastError,
  toastSuccess,
} from "../../../libs/react-mpk/services/sweetToast.service";

const defaultData = () => ({
  // string: "",
  // number: 0,
  // boolean: false,
  // multiCheckbox: [""],
});

const ProductTemplateUpdateForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const basePath = "template-document/product";
  const baseId = "mod-template-document-product-update-form";
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  useEffect(() => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        selectedItem = cloneDeep(props.item);
        selectedItem.number = String(selectedItem.number);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId={baseId}
        title="Update Product"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Title",
            key: "title",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Alias",
            key: "alias",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Description",
            key: "description",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: "Price",
            key: "price",
            thousandSeparator: ".",
            decimalSeparator: ",",
            isNumericString: true,
            type: "text",
            prefix: "Rp. ",
            required: true,
          },
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: "Thumbnail",
            key: "thumbnail",
            accept: ".jpg,.jpeg,.png",
            required: false,
            style: { width: "30%" },
          },
        ]}
        onBeforeChange={(key, value) => {
          // console.log(key, value);
          return value;
        }}
        onSubmit={async (data, callback) => {
          console.log(data);
          try {
            let res = await service.updateProduct(item.id, data);
            callback("success", false);
          } catch (error) {
            callback(error, true, false);
          }
        }}
      />
    )
  );
};

export default ProductTemplateUpdateForm;
