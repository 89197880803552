import axios from "axios";
import { crud, http } from "../../../libs/react-mpk/services";

let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getReportReferalCode = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/dashboard/top-up-referal/page`,
    query,
  });
};

service.createReferalCodeReport = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/dashboard/top-up-referal/report`,
    data: data,
  });
};

export default service;
