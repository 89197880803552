import { crud, http } from "../../libs/react-mpk/services";
let service = new crud("/");
let serviceUrl = "/v1/admin";

service.getSaldo = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/peruri/saldo`,
    query,
  });
};

service.getTotalTopup = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/topup/saldo`,
    query,
  });
};

service.UsageOrder = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/dashboard/usage-order`,
    query,
  });
};

export default service;
