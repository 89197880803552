import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import service from "./Topup.service";
import serviceLogExport from "../../LogExport/logExport.service";
import t from "counterpart";
import downloadjs from "downloadjs";
import iziToast from "izitoast";
import dayjs from "dayjs";

const TopupupReport = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const baseId = "mod-report-topup";
  const basePath = "report-topup";

  return (
    <DataForm
      baseId={baseId}
      title={t.translate(`column.export`)}
      visible={visible}
      defaultData={{}}
      onRequestClose={onRequestClose}
      asDialog={true}
      style={{ marginBottom: 100 }}
      definitions={[
        {
          inputType: DataForm.inputTypes.DATETIME,
          label: "Tanggal Awal",
          key: "start",
          required: true,
          type: "datetime-local",
          use24Hour: true,
        },
        {
          inputType: DataForm.inputTypes.DATETIME,
          label: "Tanggal Akhir",
          key: "end",
          required: true,
          type: "datetime-local"
        },
      ]}
      onSubmit={async (data, callback) => {
        data.start = dayjs(data.start).format("YYYY-MM-DD HH:mm:ss");
        data.end = dayjs(data.end).format("YYYY-MM-DD HH:mm:ss");
        const tanggalAwal = new Date(data.start);
        const tanggalAkhir = new Date(data.end);

        if (tanggalAwal > tanggalAkhir) {
          iziToast.info({
            title: t.translate("word.validation"),
            message: t.translate("word.tanggalAwalTidakBolehAwal"),
          });
          callback({}, true, false);
        } else {
          try {
            let res = await service.createTopupReport(data);
            // const getFile = await serviceLogExport.downloadById(res.data.id);
            // downloadjs(getFile.data, getFile.headers.filename);
            iziToast.success({
              title: t.translate("word.download"),
              message: t.translate("word.instructionDownload"),
            });
            callback("success", false);
          } catch (error) {
            callback(error, true, false);
          }
        }
      }}
    />
  );
};

export default TopupupReport;
