import React, { Component } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/Rectangle.png";
import logoLight from "../../assets/Rectangle.png";

import logoSobatpajak from "../../assets/Rectangle.png";
import logoSobatpajakLight from "../../assets/Rectangle.png";

import env from "../../config/env";

const LogoContainer = ({ style, light, srcLogo, srcLogoLight }) => (
  <img src={light ? srcLogoLight : srcLogo} alt="logo" style={style} />
);

class Logo extends Component {
  render() {
    let {
      light = false,
      style = {},
      logoStyle = {},
      subname = null,
      className = "",
    } = this.props;
    return (
      <div
        className={`mpk-logo ${
          light ? "light" : ""
        } mpk-layout align-center ${className}`}
        style={style}
      >
        {env.theme === env.themes.PAJAKKU ? (
          <LogoContainer
            style={logoStyle}
            {...{ light, srcLogo: logo, srcLogoLight: logoLight }}
          />
        ) : null}
        {env.theme === env.themes.SOBATPAJAK ? (
          <LogoContainer
            style={logoStyle}
            {...{
              light,
              srcLogo: logoSobatpajak,
              srcLogoLight: logoSobatpajakLight,
            }}
          />
        ) : null}
        {subname ? (
          <div
            className="mpk-margin-N margin-left mpk-font-size-NS"
            style={{ opacity: 0.72 }}
          >
            {subname}
          </div>
        ) : null}
      </div>
    );
  }
}

Logo.propTypes = {
  style: PropTypes.object,
  light: PropTypes.bool,
};

export default Logo;
