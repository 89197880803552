import { React, useEffect, useState } from "react";
import { DataForm, Logo } from "../../../libs/react-mpk/components";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import "./AccountVerification.scss";
import { Button, Dialog, FontIcon, Text } from "react-md";
import { inject, observer } from "mobx-react";
import logoVerify from "../../../assets/verif.png";
import {
  toastError,
  toastSuccess,
} from "../../../libs/react-mpk/services/sweetToast.service";
import { http } from "../../../libs/react-mpk/services";
import ReCAPTCHA from "react-google-recaptcha";

const AccountVerification = ({ envStore, navigationStore }) => {
  const [item, setItem] = useState({
    email: "",
    captchaCode: "",
  });
  const [showCaptcha, setShowCaptcha] = useState(false);
useEffect(() => {
let dataEmail = sessionStorage.getItem('verificationEmail')
setItem({...item,email:dataEmail})
}, [])
const handleRecaptchaVerify = (response) => {
  if (response) {
    setTimeout(() => {
      item.captchaCode=response
      setShowCaptcha(false);
      onSubmit()
    }, 500);

  }
};
  const onSubmit = async () => {
    try {
      let { host } = envStore.env.sso;
      await http.post(
        `${host}/api/account/resend_activation`,
        item,
        {},
        {
          useDefaultHost: false,
          useDefaultBaseUrl: false,
          useDefaultHeader: false,
        }
      );
      toastSuccess("","Silahkan cek email anda, jika akun anda sudah terdaftar di website kami maka email yang berisi link aktivasi akan dikirimkan");
    } catch (error) {
      toastError("", error);
    }
  };
  return (
    <div>
      <div className="container-paper">
        <div className="container">
          <div className="brand">
            <Logo
              light={false}
              className="mpk-logo mpk-layout align-center"
              logoStyle={{
                height: "90px",
              }}
            />
          </div>
          <h1 className="decorative-text">Brand Name</h1>
          <FormWrapper
            baseId="mpk-form-wrapper-id"
            style={{
              maxWidth: "600px",
              maxHeight: "60rem",
              padding: 20,
              height: "100%",
              boxShadow:
                "0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 40%)",
              borderRadius: "15px",
              backgroundColor: "#FFFFFF",
              marginTop: -50,
            }}
            defaultData={[]}
            definitions={[
              {
                render: (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h2 className="subTitle">Account Verification</h2>
                  </div>
                ),
              },
              {
                render: (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={logoVerify} width={"276px"} height={"250px"} />
                  </div>
                ),
              },
              {
                render: (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h4 className="subTitle">Please check your email</h4>
                  </div>
                ),
              },
              {
                render: (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Text className="text1">
                      If you don’t receive the verification email, please check
                      the spam folder in your email or <br />
                      <a onClick={()=>setShowCaptcha(true)}>resend the verification email</a>
                    </Text>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <Dialog
        onRequestClose={() => {
          setShowCaptcha(false);
        }}
        visible={showCaptcha}
      >
        <ReCAPTCHA
          sitekey={envStore.env.captcha.siteKey}
          onChange={handleRecaptchaVerify}
        />
      </Dialog>
    </div>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(AccountVerification));
