import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Login, Register, Forgot, AccountVerification } from "../modules";

export const Auth = ({ history }) => {
  return (
    <div background-color="red">
      {/* <Appbar showApp /> */}
      <Router history={history}>
        <Switch>
          <Route path="/auth/login" render={(props) => <Login {...props} />} />
          <Route
            path="/auth/register"
            render={(props) => <Register {...props} />}
          />
          <Route
            path="/auth/forgot"
            render={(props) => <Forgot {...props} />}
          />
          <Route
            path="/auth/account-verification"
            render={(props) => <AccountVerification {...props} />}
          />
        </Switch>
      </Router>
    </div>
  );
};
