class chartData{
  constructor(
    category      = 'string', 
    key           = 'string', 
    value         = 0, 
    dataConfig    = {type: 'scatter'}, 
    chartConfig   = null
  ){
    this.category = category
    this.key = key 
    this.value = value 
    this.dataConfig = dataConfig
    this.chartConfig = chartConfig
  }
}

export default chartData