import React, { useState } from 'react'
import Modal from './Modal';
import { Button } from 'react-md';
import t from 'counterpart';

function Info(props) {
  let {
    baseId          = 'react-mpk-modal-confirm',
    onRequestClose  = () => {},
    ...dialogProps
  } = props;

  return (
    <Modal 
      baseId={baseId}
      modal={true}
      onRequestClose={onRequestClose}
      footer={(
        <>
          <Button 
            id={`${baseId}-no`}
            onClick={onRequestClose}
          >
            {t.translate('mpk.column.close')}
          </Button>
        </>
      )}
      {...dialogProps}
    />
  )
}

export default Info
