import React, { useEffect, useState } from 'react';
import { DataForm } from '../../libs/react-mpk/components';
import { cloneDeep } from 'lodash'
import t from 'counterpart'

const defaultData = () => ({
	"name": "",
	"description": "",
	"code": "",
	"url": "",
	"status": true,
	"hostPermission": false,
})

const TableSampleForm = ({
	visible = false,
	onRequestClose = () => { },
	...props
}) => {
	let [item, setItem] = useState(null)
	let [ready, setReady] = useState(true)
	// useEffect(() => {
	// 	if (visible) {
	// 		let selectedItem = defaultData();
	// 		if (props.item) {
	// 			selectedItem = cloneDeep(props.item)
	// 			selectedItem.number = String(selectedItem.number)
	// 		}
	// 		setItem(selectedItem)
	// 	} else {
	// 		setItem(defaultData())
	// 	}
	// 	setReady(visible)
	// }, [visible])

	return ready && (
		<DataForm
			baseId="produk-form"
			title={t('modules.product.formProduk')}
			visible={visible}
			// data={item}
			onRequestClose={onRequestClose}
			asDialog={true}
			defaultData={props.item || defaultData()}
			definitions={[
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t("modules.product.column.name"),
					key: 'name'
				},
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t("modules.product.column.description"),
					key: 'description',
				},
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t("modules.product.column.code"),
					key: 'code',
				},
				{
					inputType: DataForm.inputTypes.INPUT,
					label: t("modules.product.column.url"),
					key: 'url',
				},
				{
					inputType: DataForm.inputTypes.CHECKBOX,
					label: t("modules.product.column.active"),
					key: 'status'
				},
				{
					inputType: DataForm.inputTypes.CHECKBOX,
					label: t("modules.product.column.hostPermission"),
					key: 'hostPermission'
				},
			]}
			onBeforeChange={(key, value) => {
				console.log(key, value)
				return value
			}}
			onSubmit={(data, callback) => {
				console.log(data)
				callback('success', false)
			}}
		/>
	)
}

export default TableSampleForm