/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { DataForm, DataTable, DividerLabel } from "../../../../libs/react-mpk/components";
import { TableWrapper } from "../../../../libs/react-mpk/wrapper"
import { toast } from "../../../../libs/react-mpk/services"
import { inject, observer } from "mobx-react";
import { Button, Select, TextField } from "react-md";
import _ from "lodash";
import t from 'counterpart'
const AddProduk = ({ visible = false, onRequestClose = null, baseId, item, ...props }) => {
    const [ready, setReady] = useState(true)
    const [active, setActive] = useState(1)
    useEffect(() => {
        if (visible) {
            setReady(true)
        }
    }, [visible])
    const [data, setData] = useState({
        profile_nama: '',
        email: '',
        instansi: '',
        telepon: '',
        wa: '',
        tujuan: '',
        nama: '',
        isme: false,
        agree: false
    })
    const [dataBackend, setDataBackend] = useState([])
    const _handleInputParameter = (value, i, field) => {
        setDataBackend(e => {
            let draf = [...e]
            draf[i][field] = value
            return draf
        })
    }
    const _handleDeleteParameter = (i) => {
        setDataBackend(e => {
            let draf = [...e]
            draf.splice(i, 1)
            return draf
        })
    }
    const _handleAddParameter = (i) => {
        setDataBackend(e => {
            let draf = [...e]
            draf.push(
                {
                    name: '',
                    description: '',
                    location: 'path',
                    jenisData: 'string',
                    mandatory: 'tidak'
                }
            )
            return draf
        })
    }
    return ready && (
        <DataForm
            baseId="form-resource"
            title={t('modules.product.tab.resources.formResources')}
            className="mpk-full full-height"
            style={{
                width: '80vw',
            }}
            asDialog={true}
            visible={visible}
            onRequestClose={() => {
                setReady(false)
                onRequestClose()
            }}
            defaultData={
                data
            }
            definitions={[
                {
                    render: (
                        <DividerLabel className='mpk-margin-N margin-top'>
                            {t("column.general")}
                        </DividerLabel>
                    )
                },
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t("modules.product.column.name"),
                    key: 'name'
                },
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t("modules.product.column.code"),
                    key: 'code',
                },
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t("modules.product.column.url"),
                    key: 'url',
                },
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t("modules.product.tab.resources.clientPath"),
                    key: 'url',
                },
                {
                    inputType: DataForm.inputTypes.INPUT,
                    label: t("modules.product.column.description"),
                    key: 'description',
                },
                {
                    inputType: DataForm.inputTypes.DIVIDER,
                    label: t("modules.product.tab.resources.method")
                },
                {
                    inputType: DataForm.inputTypes.RADIO,
                    label: "Get",
                    key: "get",
                    name: "method",
                    className: "mpk-full full-width",
                    value: 'get',
                },
                {
                    inputType: DataForm.inputTypes.RADIO,
                    label: "Post",
                    key: "post",
                    name: "method",
                    className: "mpk-full full-width",
                    value: 'post',
                },
                {
                    inputType: DataForm.inputTypes.RADIO,
                    label: "Put",
                    key: "put",
                    name: "method",
                    className: "mpk-full full-width",
                    value: 'put',
                },
                {
                    inputType: DataForm.inputTypes.RADIO,
                    label: "Detele",
                    key: "delete",
                    name: "method",
                    className: "mpk-full full-width",
                    value: 'delete',
                },
                {
                    inputType: DataForm.inputTypes.RADIO,
                    label: "Pacth",
                    key: "pacth",
                    name: "method",
                    className: "mpk-full full-width",
                    value: 'pacth',
                },
                {
                    render: (
                        <DividerLabel className='mpk-margin-N margin-top'>
                            {t("modules.product.tab.resources.parameter")}
                        </DividerLabel>
                    )
                },
                {
                    render: (
                        <DataTable
                            baseId={'tabel-backend-server'}
                            data={dataBackend}
                            showIndex={false}
                            className="mpk-data-form-list-table"
                            columns={[
                                {
                                    label: t("modules.product.column.name"),
                                    searchable: false,
                                    sortable: false,
                                    key: 'name',
                                    render: (item, index) => (
                                        <TextField
                                            id={`text-field-name-${index}`}
                                            className='mpk-margin-S margin-top margin-bottom'
                                            key={'name'}
                                            style={{
                                                width: 120
                                            }}
                                            type='text'
                                            placeholder={t("modules.product.column.name")}
                                            value={item.name}
                                            onChange={(e) => {
                                                _handleInputParameter(e, index, 'name')
                                            }}
                                        />
                                    )
                                },
                                {
                                    label: t("modules.product.column.description"),
                                    searchable: false,
                                    sortable: false,
                                    key: 'description',
                                    render: (item, index) => (
                                        <TextField
                                            id={`text-field-description-${index}`}
                                            style={{
                                                width: 130
                                            }}
                                            className='mpk-margin-S margin-top margin-bottom'
                                            key={'description'}
                                            type='text'
                                            placeholder={t("modules.product.column.description")}
                                            value={item.name}
                                            onChange={(e) => {
                                                _handleInputParameter(e, index, 'description')
                                            }}
                                        />
                                    )
                                },
                                {
                                    label: t("modules.product.tab.resources.location"),
                                    searchable: false,
                                    sortable: false,
                                    key: 'location',
                                    render: (item, index) => (
                                        <Select
                                            onChange={async (value) => {
                                                _handleInputParameter(value, index, 'location')
                                            }}
                                            style={{
                                                width: 110
                                            }}
                                            value={item.location}
                                            placeholder={t("modules.product.tab.resources.location")}
                                            id={`text-field-location-${index}`}
                                            options={[
                                                { label: "path", value: "path" },
                                                { label: "query", value: "query" },
                                                { label: "body", value: "body" },
                                                { label: "header", value: "header" },
                                                { label: "cookie", value: "cookie" },
                                            ]}
                                        />

                                    )
                                },
                                {
                                    label: t("modules.product.tab.resources.jenisData"),
                                    searchable: false,
                                    sortable: false,
                                    key: 'jenisData',
                                    render: (item, index) => (
                                        <Select
                                            onChange={async (value) => {
                                                _handleInputParameter(value, index, 'jenisData')
                                            }}
                                            style={{
                                                width: 130
                                            }}
                                            value={item.jenisData}
                                            id={`text-field-jenisData-${index}`}
                                            options={[
                                                { label: "string", value: "string" },
                                                { label: "number", value: "number" },
                                                { label: "boolean", value: "boolean" },
                                                { label: "array", value: "array" },
                                                { label: "date", value: "date" },
                                                { label: "dateTime", value: "dateTime" },
                                                { label: "file", value: "file" },
                                                { label: "object", value: "object" },
                                            ]}
                                        />
                                    )
                                },
                                {
                                    label: t("modules.product.tab.resources.mandatory"),
                                    searchable: false,
                                    sortable: false,
                                    key: 'mandatory',
                                    render: (item, index) => (
                                        <Select
                                            onChange={async (value) => {
                                                _handleInputParameter(value, index, 'mandatory')
                                            }}
                                            style={{
                                                width: 100
                                            }}
                                            value={item.mandatory}
                                            placeholder={t("modules.product.tab.resources.mandatory")}
                                            id={`text-field-mandatory-${index}`}
                                            options={[
                                                { label: "ya", value: "ya" },
                                                { label: "tidak", value: "tidak" },
                                            ]}
                                        />
                                    )
                                },
                            ]}
                            itemActions={[
                                {
                                    label: t.translate('mpk.column.delete'),
                                    iconClassName: 'mdi mdi-delete ',
                                    onClick: (item, i) => {
                                        _handleDeleteParameter(i)
                                    }
                                }
                            ]}
                            loading={false}
                            fit={false}
                        />
                    )
                },
                {
                    render: (
                        // <div>
                        <Button
                            // title='Tambah'
                            className='mpk-margin-N margin-top'
                            theme='secondary'
                            themeType='contained'
                            type='button'
                            onClick={_handleAddParameter}
                            style={{
                                color: 'white'
                            }}
                        >
                            Tambah
                        </Button>
                        // </div>
                    )
                }

            ]}
            onChange={(data, key, value) => {
                // setData(data)
                return data
            }}
            submitLabel={t("column.simpan")}
            onSubmit={async (data, callback) => {
                if (active === 1) {
                    if (!data.isme) {
                        callback(new Error(t.translate('column.notIsme')), true, false)
                    } else if (!data.agree) {
                        callback(new Error(t.translate('column.notAgreeAlert')), true, false)
                    } else {
                        setActive(2)
                        callback('Formulir pendaftaran telah terisi', false, false)
                    }
                } else {
                    try {
                        let api = localStorage.getItem(`selected-item-api`)
                        if (!api) {
                            callback('Pilih API Terlebih dahulu', true, false)
                        } else {
                            data.profile = {
                                nama: data.profile_nama,
                                email: data.email,
                                instansi: data.instansi,
                                telepon: data.telepon[0],
                                wa: data.wa[0],
                                tujuan: data.tujuan,
                            }
                            delete data.profile_nama
                            delete data.email
                            delete data.instansi
                            delete data.telepon
                            delete data.wa
                            delete data.tujuan
                            data.tipeLayanan = 'Development'
                            data.status = 'Belum Proses'
                            data.api = JSON.parse(api)
                            // await service.postService(data, isAdmin)
                            TableWrapper.reload(baseId)
                            callback(item ? "Data Berhasil Diedit" : "Data Berhasil Ditambah", false, true)
                            setActive(1)
                            setReady(false)
                            onRequestClose()
                        }
                    } catch (error) {
                        callback(error, true, false)
                    }
                }
            }}
        />
    );
};

export default inject("envStore", "authStore")(observer(AddProduk));
