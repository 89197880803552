import React, { useState, useEffect } from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { Hint } from '../../../../../../libs/react-mpk/components'
import DetailOperations from './DetailOperations'

const SSP = ({
    getLabel,
    onNextTab,
    ...props
}) => {
    const [ready, setReady] = useState(false)
    const [panels, onKeyDown] = usePanels({
        idPrefix: 'spt-1771-SSP',
        count: 2,
        defaultExpandedIndex: 0,
    })

    useEffect(() => {
        setReady(true)
    }, [])

    const [panel1Props, panel2Props] = panels;
    return ready && (
        <div
            className="mpk-margin-C mpk-animation slide-in mpk-margin-XL margin-top"
            style={{
                width: '100%',
                maxWidth: 1024
            }}
        >
            <ExpansionList onKeyDown={onKeyDown}>
                {[
                    {
                        label: 'View',
                        render: <DetailOperations />,
                        panelProps: panel1Props
                    },
                ].map((p, i) => (
                    <ExpansionPanel
                        key={`spt-1771-SSP-panel-${i}`}
                        {...p.panelProps}
                        header={<div className="mpk-font size-M weight-M">{p.label}</div>}
                    >
                        {p.render}
                    </ExpansionPanel>
                ))}
            </ExpansionList>
        </div>
    )
}


export default SSP
