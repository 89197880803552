import React, { useEffect, useState } from "react";
import { DataForm } from "../../../libs/react-mpk/components";
import service from "./ProductTemplate.service";
import { cloneDeep } from "lodash";
import {
  toastError,
  toastSuccess,
} from "../../../libs/react-mpk/services/sweetToast.service";

const defaultData = () => ({
  // string: "",
  // number: 0,
  // boolean: false,
  // multiCheckbox: [""],
});

const ProductTemplateUpdateJrmxlForm = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const basePath = "template-document/product";
  const baseId = "mod-template-document-product-update-jrmxl-form";
  let [item, setItem] = useState(null);
  let [dataTemplate, setDataTemplate] = useState(null);
  let [ready, setReady] = useState(true);

  useEffect(() => {
    const init = async () => {
      if (visible) {
        if (props.item) {
          let res = await service.getProductTemplateById(props.item.id);
          setDataTemplate(res.data.templates[0]);
          setItem(res.data);
        }
      } else {
        setItem(defaultData());
      }
    };
    init().then(() => setReady(visible));
  }, [visible]);

  return (
    ready && (
      <DataForm
        baseId={baseId}
        title="Update .jrmxl Product"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Title",
            key: "title",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Description",
            key: "description",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: "File .jrxml",
            key: "file",
            accept: ".jrxml",
            required: true,
            style: { width: "30%" },
          },
        ]}
        onBeforeChange={(key, value) => {
          // console.log(key, value);
          return value;
        }}
        onSubmit={async (data, callback) => {
          try {
            let res = await service.updateJrmxlProduct(
              item.id,
              dataTemplate.id,
              data.file
            );
            callback("success", false);
          } catch (error) {
            callback(error, true, false);
          }
        }}
      />
    )
  );
};

export default ProductTemplateUpdateJrmxlForm;
