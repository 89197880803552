import { crud, http } from "../../../libs/react-mpk/services";
let loginService = new crud("/");

const defaultConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  },
};

loginService.authLogin = async (data) => {
  return http.request({
    method: http.methods.POST,
    data: data,
    url: `/auth/oauth/authorize?session=false`,
    config: defaultConfig,
  });
};
loginService.getUser = async (data) => {
  return http.request({
    method: http.methods.GET,
    data: data,
    url: "/api/v1/account/me",
    config: defaultConfig,
  });
};

loginService.accessToken = async (code) => {
  return http.request({
    method: http.methods.POST,
    data: null,
    url: `/auth/oauth/access_token?grant_type=authorization_code&client_id=TXlRhNn4TVWq0eByWs7r7NKpSbJN5NuVRjS9HWIp&client_secret=DIHioSBepCCglmqVmgNq5Kx2YQtSXRnMvMlSiLYb&code=${code}`,
    config: defaultConfig,
  });
};
loginService.refreshToken = async (data) => {
  return http.request({
    method: http.methods.POST,
    data: data,
    url: `/auth/oauth/access_token?grant_type=refresh_token&client_id=TXlRhNn4TVWq0eByWs7r7NKpSbJN5NuVRjS9HWIp&client_secret=DIHioSBepCCglmqVmgNq5Kx2YQtSXRnMvMlSiLYb`,
  });
};

export default loginService;
