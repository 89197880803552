import React from 'react'
import { inject, observer } from 'mobx-react'
import { DropdownMenu, MenuItemLink, FontIcon } from 'react-md'

const Shortcut = (props) => {
  const { env } = props.envStore
  return (
    <div className="mpk-appbar-shortcut">
      <DropdownMenu
        id="mpk-appbar-shortcut-dropdown"
        disableDropdownIcon
        items={[...Object.keys(env.appConsole).map( key => (
          <MenuItemLink 
            href={env.appConsole[key].url}
            className="mpk-min-width-L"
          >
            {env.appConsole[key].label}
          </MenuItemLink>
        ))]}
      >
        <FontIcon iconClassName="mdi mdi-apps"/>
      </DropdownMenu>
    </div>
  )
}

export default inject('envStore')(observer(Shortcut))
