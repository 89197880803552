import React from "react";
import { Route, Switch } from "react-router-dom";

import Error from "./pages/Error";
import Internal from "./pages/Internal.routes";
import { Auth } from "./pages/Auth.routes";

const routes = () => (
  <Switch>
    <Route
      path="/auth"
      render={(props) => (
        // Auth Module
        <Auth {...props} />
      )}
    />
    <Route path="/internal" render={(props) => <Internal {...props} />} />
    <Route path="/error" render={(props) => <Error {...props} />} />
  </Switch>
);

export default routes;
