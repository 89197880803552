import React from 'react'
import { Button, FontIcon, Tooltipped, TooltipHoverModeConfig } from 'react-md'
import renderHTML from 'react-render-html'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'

const Summary = ({
  data,
  className     = '',
  render        = null,
  ...props
}) => {
  return (
    <div className={`mpk-data-view-summary mpk-paper mpk-padding-N padding-all ${className}`}>
      <div className="mpk-flex justify-between align-center">
        <div>
          <div className="mpk-font size-NS color-D3">{renderHTML(data.label)}</div>
          <div className="mpk-font weight-B size-XL">
            {data.value}
            {data.unit && (
              <span className="mpk-font size-S weight-L mpk-margin-XS margin-left">{renderHTML(data.unit)}</span>
            )}
          </div>
        </div>
        <div className="mpk-flex align-center">
          { data.trend && data.trend !== 0 && (
            <div className={`trend mpk-flex direction-column align-center ${data.trend >= 0 ? 'up' : 'down'}`}>
              <FontIcon iconClassName={data.trend >= 0 ? 'mdi mdi-arrow-up' : 'mdi mdi-arrow-down'}/>
              <span className="mpk-font size-S">{data.trend} {data.trendUnit && renderHTML(data.trendUnit)}</span>
            </div>
          )}
          {data.hint && (
            <div className="mpk-margin-N margin-left">
              <TooltipHoverModeConfig defaultDelay={0}>
                <Tooltipped
                  tooltip={data.hint}
                  focusTime={100}
                >
                  <FontIcon 
                    iconClassName="mdi mdi-information-outline"
                  />
                </Tooltipped>
              </TooltipHoverModeConfig>
            </div>
          )}
        </div>
      </div>
      {render ? <div className="mpk-margin-N margin-top">{render(data)}</div> : null}
    </div>
  )
}

export default inject('modalStore')(observer(Summary))
