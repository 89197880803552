// Helper function to convert Base64 to Blob
const base64toBlob = (base64Data) => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: "application/pdf" });
};

const handleViewPDF = (base64) => {
  const pdfBlob = base64toBlob(base64);
  const pdfUrl = URL.createObjectURL(pdfBlob);
  const newTab = window.open();
  newTab.document.open();
  newTab.document.write(
    `<iframe src="${pdfUrl}" width="100%" height="100%"></iframe>`
  );
  newTab.document.close();
};

export default handleViewPDF;
