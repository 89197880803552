import { inputTypes } from '../config/constant'

class dataFormDefinition{
  constructor(
    inputType     = inputTypes.INPUT, 
    label         ='string', 
    key           ='string', 
    validation    = null, 
    props         = {}
  ){
    this.inputType = inputType
    this.label = label 
    this.key = key
    this.validation = validation
    Object.assign(this, props)
  }
}

export default dataFormDefinition