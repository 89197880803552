import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import notificationsService from "./notifications.service";
import { FontIcon, Button, Chip, List, ListItem } from "react-md";
import ErrorService from "../../services/errorService";
import { inject, observer } from "mobx-react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { DataForm } from "../../libs/react-mpk/components";
import LoadingOverlay from "react-loading-overlay";
import { convertUtcToCurrentUserTime } from "../../utilities";

const Notifications = ({
  className = "",
  showCommandbar = true,
  modalStore,
  history,
  ...props
}) => {
  const basePath = "notifications";
  const baseId = "mod-notifications";
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [fetchParam, setFetchParam] = useState([]);
  const [data, setData] = useState([]);
  let [search, setSearch] = useState({});

  var actions = [];

  var itemActions = [
    new TableWrapper.action(
      t.translate("word.view"),
      "mdi mdi-eye",
      async (item) => {
        const { title, topic, body, read, readDate, send, sendDate } = item;
        const data = {
          title,
          topic,
          body,
          read,
          readDate,
          send,
          sendDate,
        };
        modalStore.showInfo({
          title: ``,
          width: 1000,
          children: (
            <>
              <List>
                {Object.keys(data).map((key) => {
                  return (
                    <ListItem
                      key={key}
                      primaryText={t.translate(`column.${key}`)}
                      secondaryText={
                        key.includes("Date")
                          ? convertUtcToCurrentUserTime(data[key])
                          : data[key]?.toString()
                      }
                    ></ListItem>
                  );
                })}
              </List>
            </>
          ),
        });
      },
      true
    ),
  ];

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
        <TableWrapper
          selectable={true}
          useFilter={false}
          baseId={baseId}
          title={t.translate(`modules.${basePath}.title`)}
          className={className}
          commandbar={{
            style: {
              backgroundColor: "white",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: 15,
            },
          }}
          defaultData={[]}
          defaultSortBy="date"
          sideHeader={
            <DataForm
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName="mdi mdi-magnify"
              submitLabel={`${t.translate("word.search")}`}
              additionalAction={[
                {
                  show: true,
                  render: () => (
                    <Button
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        setSearch({});
                        localStorage.removeItem(`${baseId}-saved-search`);
                        setTimeout(() => {
                          if (props.isPicker) {
                            document
                              .getElementsByClassName("mdi-reload")[1]
                              .click();
                          } else {
                            document
                              .getElementsByClassName("mdi-reload")[0]
                              .click();
                          }
                        }, 1000);
                      }}
                    >
                      {t.translate("word.delete")}
                    </Button>
                  ),
                },
              ]}
              onSubmit={(values, callback) => {
                if (values["createdDate.greaterOrEqualThan"])
                  values["createdDate.greaterOrEqualThan"] = new Date(
                    values["createdDate.greaterOrEqualThan"]
                  );
                if (values["createdDate.lessOrEqualThan"])
                  values["createdDate.lessOrEqualThan"] = new Date(
                    values["createdDate.lessOrEqualThan"]
                  );
                setSearch(values);
                localStorage.setItem(
                  `${baseId}-saved-search`,
                  JSON.stringify(search)
                );
                setTimeout(() => {
                  if (props.isPicker) {
                    document.getElementsByClassName("mdi-reload")[1].click();
                  } else {
                    document.getElementsByClassName("mdi-reload")[0].click();
                  }
                  callback("", false, false, false);
                }, 1000);
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div
                        className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                      >
                        <p>{t.translate(`word.searching`)}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  inputType: inputTypes.INPUT,
                  label: `${t.translate(`word.search`)} ${t.translate(
                    `column.title`
                  )}`,
                  key: "title.contains",
                  type: "text",
                },
              ]}
            />
          }
          columns={[
            {
              label: t.translate("column.title"),
              searchable: true,
              sortable: true,
              key: "title",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.title,
            },
            {
              label: t.translate("column.topic"),
              searchable: true,
              sortable: true,
              key: "topic",
              type: TableWrapper.dataTypes.STRING,
              render: (item) => item.topic,
            },
            {
              label: t.translate("column.send"),
              key: "send",
              sortable: true,
              searchable: true,
              render: (d) => {
                if (d.send) {
                  return (
                    <Chip className="chip-success">
                      {d.send.toString().toUpperCase()}
                    </Chip>
                  );
                } else {
                  return (
                    <Chip className="chip-error">
                      {d.send.toString().toUpperCase()}
                    </Chip>
                  );
                }
              },
            },
            {
              label: t.translate("column.sendDate"),
              searchable: true,
              sortable: true,
              key: "sendDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.sendDate,
            },
            {
              label: t.translate("column.read"),
              key: "read",
              sortable: true,
              searchable: true,
              render: (d) => {
                if (d.read) {
                  return (
                    <Chip className="chip-success">
                      {d.read.toString().toUpperCase()}
                    </Chip>
                  );
                } else {
                  return (
                    <Chip className="chip-error">
                      {d.read.toString().toUpperCase()}
                    </Chip>
                  );
                }
              },
            },
            {
              label: t.translate("column.readDate"),
              searchable: true,
              sortable: true,
              key: "readDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.readDate,
            },
            {
              label: t.translate("column.createdDate"),
              searchable: true,
              sortable: true,
              key: "createdDate",
              type: TableWrapper.dataTypes.DATE,
              render: (item) => item.createdDate,
            },
          ]}
          actions={actions}
          itemActions={itemActions}
          onFetchData={(params) => {
            return new Promise(async (resolve, reject) => {
              try {
                params = {
                  ...search,
                  ...params,
                  sort: "createdDate,DESC",
                };
                if (params.search) {
                  params.size = 9999;
                }
                var getPage = null;

                getPage = await notificationsService.getNotifications(params);

                setFetchParam(params);
                setData(getPage.data);
                getPage.headers["x-pagination-count"] =
                  getPage.headers["x-total-count"];
                resolve(getPage);
              } catch (e) {
                setFetchParam(params);
                resolve();
                ErrorService(e);
              }
            });
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={true}
        />
      </LoadingOverlay>
    </>
  );
};

export default inject("envStore", "modalStore")(observer(Notifications));
